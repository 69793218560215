// Settings.js
import React from "react";

import { useUserStateContext } from "../../../contexts/UserStateContext";

import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  lightModeTheme,
  darkModeTheme,
  texasModeTheme,
  testMagentaTheme,
} from "../../Themes/Themes";
import {
  casaLogo,
  casaLogoColor,
  texasLogo,
  texasLogoColor,
  testLogo,
} from "../../Themes/Logos";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

const Settings = (props) => {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  async function setCasaSettings(paramToChange, value) {
    let themeObj = paramToChange === "theme" ? value : userState.colorTheme;
    let theme = "";

    if (themeObj === lightModeTheme) {
      theme = "Light";
    } else if (themeObj === darkModeTheme) {
      theme = "Dark";
    } else if (themeObj === texasModeTheme) {
      theme = "Texas";
    } else {
      theme = "Test_Magenta_Theme";
    }

    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);

      const settingsObj =
        paramToChange === "background"
          ? {
              SysFunc: "SetSettings",
              Background: value,
              Theme: theme,
            }
          : {
              SysFunc: "SetSettings",
              Background: userState.bgVideoSrc,
              Theme: theme,
            };

      const response = await axios.get(APIState.SysAPI, {
        params: settingsObj,
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      return response.data;
    } catch (e) {}
  }

  const changeColorTheme = (theme) => {
    let logoSettings = { appBarLogo: testLogo, colorLogo: testLogo }; // Default

    if (theme === lightModeTheme || theme === darkModeTheme) {
      logoSettings = { appBarLogo: casaLogo, colorLogo: casaLogoColor };
    } else if (theme === texasModeTheme) {
      logoSettings = { appBarLogo: texasLogo, colorLogo: texasLogoColor };
    }

    updateUserState({ ...logoSettings, colorTheme: theme });
    setCasaSettings("theme", theme);
  };

  const changeBackgroundVideo = (background) => {
    updateUserState({ bgVideoSrc: background });
    setCasaSettings("background", background);
  };

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          my: 4,
          p: 4,
          borderRadius: "12px",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Header */}
        <Typography variant="h5" fontWeight={600} mb={3}>
          Account Settings
        </Typography>
        <Divider sx={{ mb: 3 }} />

        {/* User Info and Organization Section */}
        <Grid container spacing={3} alignItems="center" mb={4}>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexShrink: 0,
              minWidth: "38%",
            }}
          >
            <Avatar src={userState.PicURL} sx={{ width: 72, height: 72 }} />
            <Box>
              <Typography variant="h5" fontWeight={600}>
                {userState.name}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userState.departmentTitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <InputLabel id="organization-select">Organization</InputLabel>
              <Select
                label="Organization"
                labelId="organization-select"
                id="organization-select"
                value={"casarr"}
                onChange={(event) => console.log(event.target.value)}
              >
                <MenuItem value={"casarr"}>Casa Round Rock</MenuItem>
                {/* <MenuItem value={"casasa"}>Casa San Antonio</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 3 }} />

        {/* Appearance Settings */}
        <Typography
          variant="subtitle2"
          fontWeight={500}
          mb="1.25vh"
          sx={{ textDecoration: "underline" }}
        >
          Appearance
        </Typography>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="bgVideoSrc-select">Background</InputLabel>
              <Select
                label="Background"
                labelId="bgVideoSrc-select"
                id="bgVideoSrc-select"
                value={userState.bgVideoSrc}
                onChange={(event) => changeBackgroundVideo(event.target.value)}
              >
                <MenuItem value={"Casa"}>Casa</MenuItem>
                <MenuItem value={"Longhorns"}>Longhorns</MenuItem>
                <MenuItem value={"Fireplace"}>Fireplace</MenuItem>
                <MenuItem value={"Christmas"}>Christmas</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="theme-select">Theme</InputLabel>
              <Select
                label="Theme"
                labelId="theme-select"
                id="theme-select"
                value={userState.colorTheme}
                onChange={(event) => changeColorTheme(event.target.value)}
              >
                <MenuItem value={lightModeTheme}>Light</MenuItem>
                <MenuItem value={darkModeTheme}>Dark</MenuItem>
                <MenuItem value={texasModeTheme}>Texas</MenuItem>
                <MenuItem value={testMagentaTheme}>Test Magenta</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider sx={{ mb: 3 }} />

        {/* Sign Out Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ py: 1.5, fontWeight: 600 }}
          onClick={signOut}
        >
          Sign Out
        </Button>
      </Paper>
    </div>
  );
};

export default Settings;
