import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  Paper,
  Typography,
  Grid,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  useTheme,
  Divider,
  InputBase,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./Messages.css"; // Import CSS for transitions
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { useAPIContext } from "../../../contexts/APIContext";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

const NewMessageModal = (props) => {
  const theme = useTheme();

  const [employeesList, setEmployeesList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const { APIState } = useAPIContext();
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const { userState, updateUserState } = useUserStateContext();

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedEmployees(newValue);
  };

  useEffect(() => {
    async function getUsers() {
      setLoadingEmployees(true);
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      try {
        const response = await axios.get(APIState.SysAPI, {
          params: {
            SysFunc: "GetUsers",
            ActiveUser: 1,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        if (response.data && response.data.length > 0) {
          setEmployeesList(response.data);
        }
        setLoadingEmployees(false);
        return response.data;
      } catch (e) {}
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      sx={{
        width: "100%",
        mb: 2,
        padding: "1rem",
        position: "relative",
        // backgroundColor: "inherit",
        borderRadius: "8px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <CasaCloseButton handleClose={props.closeModalFunction} />
      <div
        style={{
          alignContent: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Typography
          variant="h4"
          color="white"
          style={{
            alignItems: "center",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          New Chat
        </Typography>

        <Typography variant="h5" color="white" sx={{ paddingTop: "16px" }}>
          {loadingEmployees ? "Please wait..." : "Add Member(s) To New Chat"}
        </Typography>
        <br />
        <Autocomplete
          multiple
          options={employeesList}
          disabled={loadingEmployees}
          groupBy={(option) => option.FullName.substring(0, 1)}
          id="tags-outlined"
          onChange={handleAutocompleteChange}
          getOptionLabel={(option) => option.FullName}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={loadingEmployees ? "Loading..." : "Add Member(s)"}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginTop: "3vh",
            marginBottom: "1vh",
          }}
          onClick={(e) => {
            props.createNewMessageGroup(selectedEmployees);
          }}
        >
          Message
        </Button>
      </div>
    </Paper>
  );
};

const Messages = (props) => {
  const theme = useTheme();
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [scrollDownCount, setScrollDownCount] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [conversationData, setConversationData] = useState([]);
  const [loadingConversationList, setLoadingConversationList] = useState(false);
  const [loadingConversationData, setLoadingConversationData] = useState(false);
  const [newChatOpen, setNewChatOpen] = useState(false);
  const [messageText, setMessageText] = useState("");
  const bottomRef = useRef(null);
  const { userState, updateUserState } = useUserStateContext();
  const { APIState } = useAPIContext();

  // API Functions
  async function getConversationList() {
    setLoadingConversationList(true);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetMsgGroups",
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      setLoadingConversationList(false);
      return response.data;
    } catch (e) {}
  }

  async function getConversation(conversation) {
    setLoadingConversationData(true);
    setConversationData([]);
    setSelectedConversation(conversation);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetMsgs",
          MsgGroup: conversation.GroupID,
          MsgTo: conversation.MsgTo,
          MsgDate: "",
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      setConversationData(response.data);
      setScrollDownCount(scrollDownCount + 1);
      setLoadingConversationData(false);
      return response.data;
    } catch (e) {}
  }

  async function setMessage(message) {
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "SetMsg",
          MsgGroup:
            message.MessageGroupX !== "0" ? message.MessageGroupX : null,
          MsgTo: message.MessageGroupX !== "0" ? null : message.MessageToID,
          MsgText: message.MessageText,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      return response.data;
    } catch (e) {}
  }
  // End of API Functions

  const handleConversationSelect = (conversation) => {
    setMessageText("");
    getConversation(conversation);
  };

  const containerRef = useRef(null);
  const { casaWindowState } = useCasaWindowContext();

  async function fetchData() {
    const allConvos = await getConversationList(); // Await the result here
    if (allConvos && allConvos.length > 0) {
      setConversations(allConvos); // Set the conversations with the fetched data
      setSelectedConversation(allConvos[0]);
      // Set default conversation if width >= 900
      return allConvos[0];
    }
    return [];
  }

  async function messageStartup() {
    const data = await fetchData();

    if (!selectedConversation) {
      setSelectedConversation(data);
      getConversation(data);
    } else {
      getConversation(selectedConversation);
    }
  }

  useEffect(() => {
    if (conversationData.length > 0) {
      scrollToBottomMsg();
    }
  }, [conversationData, scrollDownCount]);

  useEffect(() => {
    messageStartup(); // Call the async function inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add dependencies as needed

  function scrollToBottomMsg() {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  function handleBack() {
    setSelectedConversation(null);
    setNewChatOpen(false);
    setMessageText("");
    // setScrollDownCount(scrollDownCount + 1);
  }

  function handleNewChatButton() {
    setNewChatOpen(true);
    setMessageText("");
    // setScrollDownCount(scrollDownCount + 1);
  }

  function handleNewChatClose() {
    setNewChatOpen(false);
    setScrollDownCount(scrollDownCount + 1);
    // setScrollDownCount(scrollDownCount+1);
  }

  async function updateGroupData(groupID, groupName, groupIDList) {
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "SetMsgGroup",
          MsgGroup: groupID,
          GroupName: groupName,
          GroupIDList: groupIDList,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      return response.data;
    } catch (e) {}
  }

  async function createNewMessageGroup(employees) {
    // add new messages after Announcements + CasaGPT
    let names = "";
    let idListString = "";
    let conversationsCopy = conversations;
    if (employees.length === 1) {
      names += employees[0].FullName;
      idListString += employees[0].EmplyID;
      conversationsCopy.splice(0, 0, {
        GroupName: names,
        GroupID: "0",
        GroupIDList: idListString,
        MsgTo: idListString,
        NewMsgCount: 0,
      });
    } else if (employees.length > 1) {
      let groupID = "";
      for (let i = 0; i < employees.length; i++) {
        names += employees[i].FullName.split(" ")[0];
        idListString += employees[i].EmplyID;
        if (i + 1 < employees.length) {
          names += ", ";
          idListString += ",";
        }
      }
      names = "Group: " + names;
      if (names.length > 25) {
        names = names.substring(0, 25) + "...";
      }
      const data = await updateGroupData(null, names, idListString);
      groupID = groupID + data.GroupID;
      conversationsCopy.splice(0, 0, {
        GroupName: names,
        GroupID: groupID,
        GroupIDList: idListString,
        MsgTo: "",
        NewMsgCount: 0,
      });
    } else {
      alert("Please select employee(s).");
      return;
    }

    setConversations(conversationsCopy);
    setSelectedConversation(conversationsCopy[0]);
    setNewChatOpen(false);
    setConversationData([]);
    setScrollDownCount(scrollDownCount + 1);
  }

  function sendMessage() {
    const updatedConversationData = conversationData;

    const newMsg = {
      MessageGroupX: selectedConversation.GroupID,
      MessageTo: selectedConversation.GroupName,
      MessageToID: selectedConversation.MsgTo,
      MessageText: messageText,
    };
    updatedConversationData.push(newMsg);
    setMessage(newMsg);
    setConversationData(updatedConversationData);
    setMessageText("");
    setScrollDownCount(scrollDownCount + 1);
  }

  //if (newChatOpen) {
  const getKey = () => {
    if (newChatOpen) return 0; // index 0 is for newChat
    return 1; // index 1 is for Messages
  };

  const compArr = [
    <div key="newChat">
      <NewMessageModal
        closeModalFunction={handleNewChatClose}
        createNewMessageGroup={createNewMessageGroup}
        setSelectedConversation={setSelectedConversation}
      />
    </div>,
    <div
      key="messages"
      ref={containerRef}
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Paper style={{ position: "relative", padding: "20px", width: "100%" }}>
        <CasaCloseButton handleClose={props.backButton} />
        <IconButton
          sx={{
            position: "absolute",
            top: -15,
            right: -15,
            zIndex: 0,
            backgroundColor: theme.palette.customColors.circleButtonColor, // Background color of the dot
            color: "#fff", // Icon color
            "&:hover": {
              backgroundColor:
                theme.palette.customColors.circleButtonColorHover, // Background color on hover
            },
          }}
          aria-label="New Message"
          onClick={handleNewChatButton}
        >
          <AddIcon />
        </IconButton>
        {/* Left Message Pane (conversation list) */}
        <Grid container spacing={3}>
          {((casaWindowState.width < 900 &&
            !selectedConversation &&
            !newChatOpen) ||
            casaWindowState.width >= 900) && (
            <Grid item xs={12} md={4}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  fontSize: "1.5em",
                  paddingLeft: "12px",
                }}
              >
                {loadingConversationList ? "Loading..." : "Messages"}
                {!loadingConversationList && (
                  <IconButton
                    sx={{ ml: "0.1vw" }}
                    onClick={() => {
                      messageStartup();
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                )}
              </Typography>
              <Box
                borderRight={1}
                pr={2}
                height={
                  casaWindowState.width < 900 ? "calc(65vh)" : "calc(55vh)"
                }
                overflow="auto"
              >
                <List>
                  {conversations.map((conversation, index) => (
                    <ListItem
                      button
                      key={index}
                      selected={selectedConversation === conversation} // Highlight if selected
                      onClick={() => handleConversationSelect(conversation)}
                      style={
                        selectedConversation === conversation
                          ? { backgroundColor: "rgba(0, 0, 0, 0.3)" }
                          : null
                      } // Darken the selected ListItem
                    >
                      <ListItemAvatar>
                        <Avatar alt={conversation.GroupName}>
                          {conversation.GroupName.substring(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={conversation.GroupName}
                        secondary={conversation.NewMsgCount + " New Messages"}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          )}
          {/* Right Message Pane (with message bubbles) */}
          {((casaWindowState.width < 900 && selectedConversation) ||
            (casaWindowState.width < 900 && newChatOpen) ||
            casaWindowState.width >= 900) && (
            <Grid item xs={12} md={8} style={{ position: "relative" }}>
              {(casaWindowState.width < 900 ||
                (casaWindowState.width < 900 && newChatOpen)) && (
                <IconButton
                  onClick={handleBack}
                  aria-label="Back"
                  style={{ paddingBottom: "10px" }}
                >
                  <ArrowBackIcon />
                  <Typography variant="h6">
                    {selectedConversation?.GroupName}
                  </Typography>
                </IconButton>
              )}
              {casaWindowState.width >= 900 && (
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "1.5em",
                    paddingBottom: "10px",
                  }}
                >
                  {loadingConversationData
                    ? "Loading..."
                    : selectedConversation?.GroupName}
                </Typography>
              )}
              <Box
                pl={2}
                style={{
                  overflow: selectedConversation ? "auto" : "hidden",
                  height:
                    casaWindowState.width < 900 ? "calc(58vh)" : "calc(48vh)",
                }}
              >
                <div>
                  {selectedConversation &&
                    Array.isArray(conversationData) &&
                    conversationData.map((message, index) => (
                      <div
                        key={index}
                        style={{
                          textAlign:
                            message.MessageTo !== userState.name
                              ? "right"
                              : "left",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor:
                              message.MessageTo !== userState.name
                                ? "#007bff"
                                : "#e8e8e8",
                            color:
                              message.MessageTo !== userState.name
                                ? "#fff"
                                : "#000",
                            borderRadius: "10px",
                            padding: "10px",
                            maxWidth: "70%",
                            wordWrap: "break-word",
                            textAlign:
                              message.MessageTo !== userState.name
                                ? "left"
                                : "inherit",
                          }}
                        >
                          {message.MessageText}
                        </div>
                      </div>
                    ))}
                  <div ref={bottomRef} />
                </div>
              </Box>
              {/* Message Text Field */}
              {selectedConversation && (
                <>
                  <Box
                    position="sticky"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: "10px 10px",
                      border: "1px solid", // Add border
                      borderRadius: "10px", // Add border radius
                    }}
                  >
                    <InputBase
                      id="new_message_text"
                      value={messageText}
                      onChange={(e) => {
                        setMessageText(e.target.value);
                      }}
                      sx={{
                        ml: 1,
                        flex: 1,
                      }}
                      placeholder={
                        loadingConversationData
                          ? "Loading Messages..."
                          : "Message"
                      }
                      inputProps={{ "aria-label": "Send Message" }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sendMessage();
                        }
                      }}
                      onFocus={(e) => {
                        setScrollDownCount(scrollDownCount + 1);
                      }}
                      fullWidth
                      disabled={
                        !selectedConversation || loadingConversationData
                      }
                    />
                    <Divider
                      sx={{ height: 38, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      aria-label="send"
                      disabled={!messageText}
                      onClick={sendMessage}
                    >
                      <SendIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>,
  ];

  return (
    <SwitchTransition>
      <CSSTransition
        key={getKey()}
        timeout={150}
        classNames="fade-messages"
        unmountOnExit
      >
        <div
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {compArr[getKey()]}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Messages;
