// InventoryReview.js
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import MenuItem from "@mui/material/MenuItem";
import { useTheme, styled } from "@mui/material/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";

import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import "./Inventory.css"; // Import CSS for transitions
import axios from "axios";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";
import CasaDetailDashComponent from "../../Resuable/CasaDetailDashComponent/CasaDetailDashComponent";
import CasaConfigureComponent from "../../Resuable/CasaConfigureComponent/CasaConfigureComponent";
import ViewAssemblyTable from "./ViewAssemblyTable";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "InvDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "InvPart",
    numeric: false,
    disablePadding: false,
    label: "Part #",
  },
  {
    id: "InvUnit",
    numeric: false,
    disablePadding: false,
    label: "Unit",
  },
  {
    id: "InvQuantity",
    numeric: false,
    disablePadding: false,
    label: "Qty",
  },
  {
    id: "InvCost",
    numeric: false,
    disablePadding: false,
    label: "Cost",
  },
  {
    id: "InvNotes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          {/* <span>-</span> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:hover": {
                color: "#fff", // Change this to the desired hover color
              },
              "&:focus": {
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },
                "&.MuiTableSortLabel-root:hover": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "&.Mui-active": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "white !important",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ThickInventoryHeader({
  name,
  rows,
  setRows,
  handleAddMode,
  setFilteredRows,
  setPage,
  isLoading,
  setIsLoading,
  backButton,
  getFilterOptions,
  filtersLoaded,
  setFiltersLoaded,
  getFilters,
  getDataFromFilters,
  searchByKeyword,
}) {
  const [selectedColumn] = React.useState("InvDescription");
  const { userState, updateUserState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const { APIState } = useAPIContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size

  async function searchParts(value) {
    if (inventoryState.type === "") {
      alert("Please select a type.");
      return;
    } else if (inventoryState.category === "" || !value) {
      alert("Please select a category.");
      return;
    }
    setIsLoading(true);
    const data = await getDataFromFilters(value);
    setRows(data);
    setIsLoading(false);
  }

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        type: value,
        category: "",
        group: "",
      });
      console.log(value, "-", "-");
      setRows([]);
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ categoryOptions: results });
      }
      updateInventory({ groupOptions: [] });
    } else if (type === "category") {
      setRows([]);
      updateInventory({
        category: value,
        group: "",
      });
      console.log(inventoryState.type, value, "-");
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ groupOptions: results });
      } else {
        updateInventory({ groupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        group: value,
      });
      console.log(inventoryState.type, inventoryState.category, value);
    }
  }

  function handleSearch() {
    searchByKeyword(inventoryState.reviewSearchKeyword);
  }

  React.useEffect(() => {
    getFilterOptions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  React.useEffect(() => {
    const filteredRows = rows.filter((row) => {
      const value = row[selectedColumn]?.toString().toLowerCase();
      return value;
    });
    setPage(0);
    setFilteredRows(filteredRows);
  }, [selectedColumn, rows, setFilteredRows, setPage]);

  function getFilterDropdowns() {
    return (
      <>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          <InputLabel id="type-label">
            {!filtersLoaded ? "Loading..." : "Type"}
          </InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={inventoryState.type}
            onChange={(event) => {
              handleUpdateInventory("type", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Type"}
            disabled={!filtersLoaded}
          >
            {inventoryState?.typeOptions?.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          <InputLabel id="category-label">
            {!filtersLoaded ? "Loading..." : "Category"}
          </InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={inventoryState.category}
            disabled={
              inventoryState.categoryOptions.length === 0 ||
              inventoryState.type === "" ||
              !filtersLoaded
            }
            onChange={(event) => {
              handleUpdateInventory("category", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Category"}
          >
            {inventoryState.categoryOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          {/* Add the InputLabel component */}
          <InputLabel id="group-label">
            {!filtersLoaded ? "Loading..." : "Group"}
          </InputLabel>

          <Select
            labelId="group-label"
            id="group"
            value={inventoryState.group}
            disabled={
              inventoryState.groupOptions.length === 0 ||
              inventoryState.category === "" ||
              !filtersLoaded
            }
            onChange={(event) => {
              handleUpdateInventory("group", event.target.value);
              if (event.target.value) {
                searchParts(event.target.value);
              }
            }}
            label={!filtersLoaded ? "Loading..." : "Group"}
          >
            {inventoryState.groupOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }

  function getSearchBar() {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          value={inventoryState.reviewSearchKeyword}
          onChange={(e) => {
            updateInventory({ reviewSearchKeyword: e.target.value });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          variant="outlined"
          placeholder="Search..."
          fullWidth
          sx={{ flex: 1, width: "900px", mt: "10px", mb: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "12px", mb: "12px" }}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Box>
    );
  }

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      <CasaCloseButton handleClose={backButton} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            // textDecoration: "underline",
            paddingTop: "1vh",
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
          variant="filterViewTitle"
          id="tableTitle"
          component="div"
        >
          {/* Review Inventory */}
          Search Inventory
          <IconButton
            onClick={() => {
              //refresh view based on selected filters/keyword
              if (!inventoryState.showFilterSearch) {
                // filters showing
                getDataFromFilters(inventoryState.group);
              } else {
                // filters hidden, text search showing
                searchByKeyword(inventoryState.reviewSearchKeyword);
              }
              updateInventory({
                showFilterSearch: !inventoryState.showFilterSearch,
              });
            }}
            sx={{ ml: 1 }}
          >
            {inventoryState.showFilterSearch ? (
              <SearchIcon />
            ) : (
              <FilterListIcon />
            )}
          </IconButton>
        </Typography>
      </div>

      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: "center", // isLargeScreen ? "space-between" : "center"
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        {inventoryState.showFilterSearch && getFilterDropdowns()}
        {!inventoryState.showFilterSearch && getSearchBar()}
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </div>
    </Paper>
  );
}

export default function InventoryReview(props) {
  const theme = useTheme();
  const { userState, updateUserState } = useUserStateContext();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("description");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [editRow, setEditRow] = React.useState(null);
  const [addMode, setAddMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState();
  const [countLoading, setCountLoading] = React.useState();
  const [showCounts, setShowCounts] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openPartsList, setOpenPartsList] = React.useState(false);
  const { inventoryState, updateInventory } = useInventoryContext();
  // const { userState, updateUserState } = useUserStateContext();
  const { APIState } = useAPIContext();
  const [countData, setCountData] = React.useState([]);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  async function getFilterOptions(isRefresh) {
    setFiltersLoaded(false);
    setIsLoading(true);
    // First, you need to retrieve data
    if (isRefresh) {
      setRows([]);
      getCatArrs();
      updateInventory({
        category: "",
        type: "",
        group: "",
        typeOptions: [],
        categoryOptions: [],
        groupOptions: [],
        reviewDepartmentIndex: userState.getDepartmentIndex(
          userState.department
        ),
      });
      const data = await getFilters();
      // Next, get the ID of the department you're looking for
      const dept =
        data[0]?.relatedRows[userState.getDepartmentIndex(userState.department)]
          ?.LevelID;
      // Finally, retrieve the options for that ID to get the correct data
      const options = data.find((filter) => filter.parentId === dept);
      updateInventory({
        filters: data,
        typeOptions: options.relatedRows,
        editTypeOptions: options.relatedRows,
      });
      if (!inventoryState.showFilterSearch) {
        // filters hidden, text search showing
        searchByKeyword(inventoryState.reviewSearchKeyword);
      }
    } else if (
      !inventoryState.group ||
      inventoryState.group === "" ||
      inventoryState.reviewDepartmentIndex !==
        userState.getDepartmentIndex(userState.department)
    ) {
      setRows([]);
      getCatArrs();
      updateInventory({
        category: "",
        type: "",
        group: "",
        typeOptions: [],
        categoryOptions: [],
        groupOptions: [],
        reviewDepartmentIndex: userState.getDepartmentIndex(
          userState.department
        ),
      });
      const data = await getFilters();
      // Next, get the ID of the department you're looking for
      const dept =
        data[0]?.relatedRows[userState.getDepartmentIndex(userState.department)]
          ?.LevelID;
      // Finally, retrieve the options for that ID to get the correct data
      const options = data.find((filter) => filter.parentId === dept);
      updateInventory({
        filters: data,
        typeOptions: options?.relatedRows,
        editTypeOptions: options?.relatedRows,
      });
      if (!inventoryState.showFilterSearch) {
        // filters hidden, text search showing
        searchByKeyword(inventoryState.reviewSearchKeyword);
      }
    } else {
      // this is where the initial search is happening
      if (inventoryState.showFilterSearch) {
        // filters showing
        getDataFromFilters(inventoryState.group);
      } else {
        // filters hidden, text search showing
        searchByKeyword(inventoryState.reviewSearchKeyword);
      }
    }
    setFiltersLoaded(true);
    setIsLoading(false);
  }

  async function getCatArrs() {
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 5,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        updateInventory({ filterArrays: response.data });
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  async function getGroupArrsByDept() {
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 6,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        console.log(response.data);
        updateInventory({ groupArrsByDept: response.data });
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  async function getCountData(id) {
    try {
      setCountLoading(true);
      setCountData(null);
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItemReview",
          ItemID: id,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setCountLoading(false);
        return [];
      } else {
        setCountData(response.data);
        setCountLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setCountLoading(false);
      return [];
    }
  }

  async function getFilters() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 0,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  async function getDataFromFilters(group) {
    if (group === "" || !group) {
      setIsLoading(false);
      setRows([]);
      return;
    }
    try {
      setIsLoading(true);
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItem",
          InvMode: 2,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          Level2Parm: inventoryState.type.split("-")[1],
          Level3Parm: inventoryState.category.split("-")[1],
          Level4Parm: group.split("-")[1],
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setIsLoading(false);
        return [];
      } else {
        setRows(response.data);
        setIsLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setIsLoading(false);
      return [];
    }
  }

  async function searchByKeyword(keyword) {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    if (keyword === "") {
      setIsLoading(false);
      setRows([]);
      return;
    }
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      setIsLoading(true);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItem",
          InvMode: 0,
          ItemID: 0,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          Level2Parm: 0,
          Level3Parm: 0,
          Level4Parm: 0,
          Keywords: inventoryState.reviewSearchKeyword,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        console.log(rows);
        return [];
      } else {
        console.log(response);
        setRows(response.data);
        setIsLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  async function saveData() {
    console.log("saving data, should be updating filter options...");

    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "SetInvItem",
          ItemID: editRow.InvID,
          Level1Parm: editRow.InvDept,
          Level2Parm: editRow.InvType,
          InvDescription: editRow.InvDescription,
          InvPart: editRow.InvPart,
          InvCost: editRow.InvCost,
          InvUnit: editRow.InvUnit,
          InvQuantity: editRow.InvQuantity,
          InvNotes: editRow.InvNotes,
          InvCategory: inventoryState.editCategory.split("-")[1],
          InvGroup: inventoryState.editGroup.split("-")[1],
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      if (response.data?.StatusCode !== "OK") {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        await getCatArrs();
        console.log(
          "editRow: ",
          editRow.InvGroup,
          " | invState: ",
          inventoryState.editGroup.split("-")[1]
        );
        const filterDataStuff = await getFilterOptions(true);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  function handleEditRow(data) {
    setEditMode(false);
    getCountData(data.InvID);
    console.log("ran");
    if (data?.InvType === inventoryState.AssemblyValue) {
      console.log("inside of here ran, ", editRow);
      updateInventory({ assembly: data });
      setEditRow(data);
    } else if (data) setEditRow(data);
  }

  function setupSearch() {
    // get group arrays for edit screen
    getGroupArrsByDept();
    // check to see if this is coming from create
    if (inventoryState.createItem) {
      handleEditRow(inventoryState.createItem);
      updateInventory({ createItem: null });
    }
  }

  function handleEditMode() {
    setEditMode(!editMode);
    setAddMode(false);
  }

  function handleAddMode() {
    setAddMode(!addMode);
    setEditMode(false);
  }

  function handleAdd() {
    const description = document.getElementById("description-textfield").value;
    const category = document.getElementById("category-textfield").value;
    const mfgNum = document.getElementById("mfg-num-textfield").value;
    const group = document.getElementById("group-textfield").value;
    const type = document.getElementById("type-textfield").value;
    const stage = document.getElementById("stage-textfield").value;
    const count = document.getElementById("count-textfield").value;
    const unit = document.getElementById("unit-textfield").value;
    const cost = document.getElementById("cost-textfield").value;
    const qty = document.getElementById("qty-textfield").value;
    const vendor = document.getElementById("vendor-textfield").value;
    const notes = document.getElementById("notes-textfield").value;

    const newRow = {
      id: rows.length + 1,
      description,
      category,
      mfgNum,
      group,
      type,
      stage,
      count,
      unit,
      cost,
      qty,
      vendor,
      notes,
    };

    setRows((prevRows) => {
      const updatedRows = [...prevRows, newRow];
      return updatedRows;
    });

    handleAddMode();
  }

  function handleSave() {
    // add POST api call here later to CasaDB
    const updatedRows = [...rows];
    const description = document.getElementById("description-textfield").value;
    // const category = document.getElementById("category-textfield").value;
    const mfgNum = document.getElementById("mfg-num-textfield").value;
    // const group = document.getElementById("group-textfield").value;
    // const type = document.getElementById("type-textfield").value;
    // const stage = document.getElementById("stage-textfield").value;
    // const count = document.getElementById("count-textfield").value;
    const unit = document.getElementById("unit-textfield").value;
    const cost = document.getElementById("cost-textfield").value;
    const qty = document.getElementById("qty-textfield").value;
    // const vendor = document.getElementById("vendor-textfield").value;
    const notes = document.getElementById("notes-textfield").value;

    editRow.InvDescription = description;
    // editRow.InvPullCategoryX = category;
    editRow.InvPart = mfgNum;
    // editRow.InvPullGroup = group;
    // editRow.InvTypeX = type;
    // editRow.InvStageX = stage;
    // editRow.InvUnitCount = count;
    editRow.InvUnit = unit;
    editRow.InvCost = cost;
    editRow.InvQuantity = qty;
    // editRow.InvVenIDX = vendor;
    editRow.InvNotes = notes;

    const index = updatedRows.findIndex((item) => item.InvID === editRow.InvID);
    updatedRows[index] = editRow;
    setRows(updatedRows);
    handleEditRow(editRow);
    let editRowCopy = editRow;
    console.log(
      "cat/group: ",
      inventoryState.editCategory,
      inventoryState.editGroup
    );
    editRowCopy.InvCategory = inventoryState.editCategory.split("-")[1];
    editRowCopy.InvGroup = inventoryState.editGroup.split("-")[1];
    setEditRow(editRowCopy);
    saveData();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  function handleClose() {
    setAddMode(false);
    setEditMode(false);
    setEditRow(null);
  }

  function addModal() {
    return (
      <>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            padding: "1rem",
            position: "relative",
            // backgroundColor: "inherit",
            borderRadius: "8px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              left: "0.5rem",
              top: "0.5rem",
              color: "#fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <span
            style={{
              fontSize: "1.5em",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Add Item
          </span>

          {/* <IconButton
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              color: "#fff",
              backgroundColor: theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor: theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={editMode ? handleSave  : handleEditMode}
          >
            {editMode ? <SaveIcon />  : <EditIcon />}
          </IconButton> */}
          <br />
          <br />
          <Grid container spacing={2}>
            {/* On smaller screens, display as 4x1 grid */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="description-textfield"
                label="Description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="category-textfield" label="Category" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="mfg-num-textfield" label="Part #" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="group-textfield" label="Group" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="type-textfield" label="Type" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="stage-textfield" label="Stage" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="count-textfield" label="Count" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="unit-textfield" label="Unit" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="cost-textfield" label="Cost" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="qty-textfield" label="Qty" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="vendor-textfield" label="Vendor" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="notes-textfield" label="Notes" />
            </Grid>
          </Grid>
          <br />
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Add
          </Button>
        </Paper>
      </>
    );
  }

  // View Entry Row Styling
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    background: `linear-gradient(45deg, ${theme.palette.customColors.darkerPrimaryColor} 30%, ${theme.palette.primary.main} 100%)`,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    // "&:first-of-type": {
    //   borderTopLeftRadius: "8px",
    //   borderBottomLeftRadius: "8px",
    // },
    // "&:last-of-type": {
    //   borderTopRightRadius: "8px",
    //   borderBottomRightRadius: "8px",
    // },
  }));

  const PlainTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    // background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.customColors.lighterPrimaryColor} 100%)`,
    // color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  }));

  const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    // padding: "2px",
    // borderRadius: "8px",
    // boxShadow: "2px 3px 5px 2px rgba(255, 105, 135, .3)",
  }));

  const TableStyled = styled(Table)(({ theme }) => ({
    // margin: "auto",
    // minWidth: 650,
    // borderCollapse: "separate",
    // borderSpacing: "0 10px",
  }));

  function editModeView() {
    return (
      <Grid container spacing={1}>
        {/* On smaller screens, display as 4x1 grid */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="description-textfield"
            label="Description"
            defaultValue={editRow?.InvDescription}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="category-textfield"
            label="Category"
            defaultValue={editRow?.InvPullCategoryX}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="mfg-num-textfield"
            label="Part #"
            defaultValue={editRow?.InvPart}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="group-textfield"
            label="Group"
            defaultValue={editRow?.InvPullGroup}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="type-textfield"
            label="Type"
            defaultValue={editRow?.InvTypeX}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="stage-textfield"
            label="Stage"
            defaultValue={editRow?.InvStageX}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="count-textfield"
            label="Count"
            defaultValue={editRow?.InvUnitCount}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="unit-textfield"
            label="Unit"
            defaultValue={editRow?.InvUnit}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="cost-textfield"
            label="Cost"
            defaultValue={editRow?.InvCost}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="qty-textfield"
            label="Qty"
            defaultValue={editRow?.InvCountQuantity}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="vendor-textfield"
            label="Vendor"
            defaultValue={editRow?.InvVenIDX}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="notes-textfield"
            label="Notes"
            defaultValue={editRow?.InvNotes}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function viewModeView() {}

  function editModal() {
    return (
      <CasaConfigureComponent
        configType="custom"
        title={"Stages"}
        closeModalFunction={() => {
          setShowCounts(false);
        }}
        content={
          <div
            style={{
              width: "60%",
              margin: "10px",
            }}
          >
            <Card
              variant="outlined"
              sx={{ p: 3, mb: 2, borderRadius: 2, boxShadow: 2 }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  gutterBottom
                  textAlign="center"
                >
                  {editRow?.InvDescription || "No Description"}
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap={3}
                  justifyContent="center"
                  textAlign="center"
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>ID:</strong> {editRow?.InvID || "N/A"}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Part #:</strong> {editRow?.InvPart || "N/A"}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Unit:</strong> {editRow?.InvUnit || "N/A"}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Cost:</strong> {editRow?.InvCost || "N/A"}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    <strong>Qty:</strong> {editRow?.InvQuantity || "N/A"}
                  </Typography>
                </Box>
                {editRow?.InvNotes && (
                  <Typography
                    variant="body2"
                    mt={2}
                    color="textSecondary"
                    textAlign="center"
                  >
                    <strong>Notes:</strong> {editRow?.InvNotes}
                  </Typography>
                )}
              </CardContent>
            </Card>

            {countData?.length > 0 ? null : (
              <>
                <Typography
                  variant="body1"
                  mt={2}
                  color="textSecondary"
                  textAlign="center"
                >
                  No stage data found.
                </Typography>
                <br />
              </>
            )}
            <Grid container spacing={2}>
              {countData?.map((count, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    variant="outlined"
                    sx={{ borderRadius: 2, boxShadow: 2 }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        textAlign="center"
                      >
                        {count.StageDesc}
                      </Typography>
                      <Typography variant="body1" textAlign="center">
                        {count.TotalQty}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        }
      />
    );
  }

  React.useEffect(() => {
    setupSearch();
  }, []);

  const tableContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [page]);

  function mainTable(tableContainerRef) {
    return (
      <>
        <ThickInventoryHeader
          name={props.name}
          rows={rows}
          setRows={setRows}
          handleAddMode={handleAddMode}
          setFilteredRows={setFilteredRows}
          setPage={setPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          backButton={props.backButton}
          getFilterOptions={getFilterOptions}
          getFilters={getFilters}
          filtersLoaded={filtersLoaded}
          setFiltersLoaded={setFiltersLoaded}
          getCatArrs={getCatArrs}
          getDataFromFilters={getDataFromFilters}
          searchByKeyword={searchByKeyword}
        />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            >
              {!isLoading && rows.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.InvID}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleEditRow(row);
                          }}
                        >
                          <TableCell>
                            {/* <InfoOutlinedIcon sx={{ color: "#555" }} /> */}
                          </TableCell>
                          <TableCell style={{ minWidth: "235px" }}>
                            {row.InvDescription}
                          </TableCell>
                          <TableCell>{row.InvPart}</TableCell>
                          <TableCell>{row.InvUnit}</TableCell>
                          <TableCell>{row.InvQuantity}</TableCell>
                          <TableCell>{row.InvCost}</TableCell>
                          <TableCell>{row.InvNotes}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <CasaIdleTableView
                  isLoading={isLoading}
                  noResultText="No results. Use the dropdowns or search bar above to locate items."
                />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  const getKey = () => {
    if (openDetails) return 0; // Assuming index 0 is for addModal
    if (showCounts) return 2;
    if (editRow && editRow.InvType !== inventoryState.AssemblyValue) return 1; // Assuming index 1 is for editModal
    if (editRow && editRow.InvType === inventoryState.AssemblyValue) {
      if (openPartsList) return 5;
      return 4;
    }
    return 3; // Assuming index 2 is for mainTable
  };

  const compArr = [
    <div key="detailsModal">
      <CasaConfigureComponent
        title={
          inventoryState.filterArrays[1]?.relatedRows?.find(
            (item) =>
              item.ExtID ===
              "2" +
                parseInt(
                  userState.getDepartmentIndex(userState.department) + 1
                ) +
                "-" +
                editRow?.InvType
          )?.LevelDesc + " Details"
        }
        configType="inv-details"
        editEnabled={true}
        editRow={editRow}
        setEditRow={setEditRow}
        handleSave={handleSave}
        closeModalFunction={() => {
          setOpenDetails(false);
        }}
      />
    </div>,
    <div key="inventoryDash">
      <CasaDetailDashComponent
        title={
          "Review " +
          inventoryState.filterArrays[1]?.relatedRows?.find(
            (item) =>
              item.ExtID ===
              "2" +
                parseInt(
                  userState.getDepartmentIndex(userState.department) + 1
                ) +
                "-" +
                editRow?.InvType
          )?.LevelDesc
        }
        dashtype="inventory-review"
        editRow={editRow}
        departmentLock={props.departmentLock}
        closeModalFunction={() => {
          props.departmentUnlock();
          setEditRow(null);
        }}
        openDetailsModal={() => {
          setOpenDetails(true);
        }}
        openCounts={() => {
          setShowCounts(true);
        }}
      />
    </div>,
    <div key="editModal">{editModal()}</div>,
    <div key="mainTable">{mainTable(tableContainerRef)}</div>,
    <div key="assemblyDash">
      <CasaDetailDashComponent
        title={
          "Review " +
          inventoryState.filterArrays[1]?.relatedRows?.find(
            (item) =>
              item.ExtID ===
              "2" +
                parseInt(
                  userState.getDepartmentIndex(userState.department) + 1
                ) +
                "-" +
                editRow?.InvType
          )?.LevelDesc
        }
        dashtype="inventory-assemblies"
        departmentLock={props.departmentLock}
        editRow={editRow}
        closeModalFunction={() => {
          props.departmentUnlock();
          setEditRow(null);
        }}
        openDetailsModal={() => {
          setOpenDetails(true);
        }}
        openPartsListModal={() => {
          setOpenPartsList(true);
        }}
        openCounts={() => {
          setShowCounts(true);
        }}
      />
    </div>,
    <div key="assembly">
      {
        <ViewAssemblyTable
          name={editRow?.InvDescription}
          title="Search Assemblies"
          backButton={() => {
            setOpenPartsList(false);
          }}
        />
      }
    </div>,
  ];

  return (
    <div>
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-inventory"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
