// InventoryAdmin.js
import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CasaAppIcon from "../../Resuable/CasaAppIcon/CasaAppIcon";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import CasaConfigureComponent from "../../Resuable/CasaConfigureComponent/CasaConfigureComponent";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { DataGrid } from "@mui/x-data-grid";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useAPIContext } from "../../../contexts/APIContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";

const InventoryAdmin = (props) => {
  const { administrationState } = useAdministrationContext(); //updateAdministration
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const { casaWindowState } = useCasaWindowContext();
  // Container width state and ref
  const appContainerRef = useRef(null);
  const [openTags, setOpenTags] = useState(false);
  const [openTypes, setOpenTypes] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [openGroups, setOpenGroups] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openEditTag, setOpenEditTag] = useState(false);
  const [openCreateTag, setOpenCreateTag] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [newRow, setNewRow] = useState({});
  const [createRow, setCreateRow] = useState({});
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  async function handleInventoryAPICall(operation, keyword) {
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);

      let response;

      if (operation === "getcat") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvCat",
            GetLevel: 0,
            Level1Parm: 0,
            Level2Parm: 0,
            Level3Parm: 0,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (operation === "getcatarrs") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvCat",
            GetLevel: 5,
            Level1Parm: 0,
            Level2Parm: 0,
            Level3Parm: 0,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (operation === "getitem") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvItem",
            InvMode: 0,
            ItemID: 0,
            Level1Parm: parseInt(
              userState.getDepartmentIndex(userState.department) + 1
            ),
            Level2Parm: 0,
            Level3Parm: 0,
            Level4Parm: 0,
            Keywords: keyword,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else {
        throw new Error(`Unknown operation: ${operation}`);
      }
      console.log("response: ", response);
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        console.log(response);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  // API
  async function getTagsByTagType(tagType) {
    setLoadingTags(true);
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvTag",
          InvTagType: tagType,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        const updatedArray = response.data.map((item, index) => ({
          ...item,
          id: index + 1, // Assign a unique id, starting at 1
        }));
        setRows(updatedArray);
        return updatedArray;
      }
    } catch (e) {
      console.log("GET call failed: ", e);
      return [];
    }
  }

  async function createNewTag() {
    console.log("createNewTag");
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      if (openTypes) {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "NewInvTypeTag",
            InvTagTypeDesc: createRow.description,
            InvTagTypeSort: createRow.sort1,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (openCategories) {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "NewInvCategoryTag",
            InvTagDeptID: createRow.department,
            InvTagTypeID: createRow.type.split("-")[1],
            InvTagCategoryDesc: createRow.description,
            InvTagCategorySort: createRow.sort1,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (openGroups) {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "NewInvGroupTag",
            InvTagDeptID: createRow.department,
            InvTagGroupDesc: createRow.description,
            InvTagGroupSort1: createRow.sort1,
            InvTagGroupSort2: createRow.sort2,
            InvTagServiceFlag: createRow.serviceFlag,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else {
        console.log("unknown tag type");
        setOpenCreateTag(false);
        return;
      }
      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        console.log(response);
        setOpenCreateTag(false);
        return [];
      } else {
        console.log(response);
        setLoadingTags(true);
        if (openTypes) {
          await getTagsByTagType("type");
        } else if (openCategories) {
          await getTagsByTagType("category");
        } else if (openGroups) {
          await getTagsByTagType("group");
        }
        setLoadingTags(false);
        setOpenCreateTag(false);
        return response.data;
      }
    } catch (e) {
      console.log("GET call failed: ", e);
      setLoadingTags(false);
      return [];
    }
  }

  async function updateTag(tagType) {
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      console.log(newRow);
      if (tagType === "type") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "SetInvTypeTag",
            InvTagTypeID: newRow.id,
            InvTagTypeDesc: newRow.description,
            InvTagTypeSort: newRow.sort1,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (tagType === "category") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "SetInvCategoryTag",
            InvTagDeptID: newRow.department,
            InvTagTypeID: newRow.type,
            InvTagCategoryID: newRow.id,
            InvTagCategoryDesc: newRow.description,
            InvTagCategorySort: newRow.sort1,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else if (tagType === "group") {
        response = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "SetInvGroupTag",
            InvTagDeptID: newRow.department,
            InvTagGroupID: newRow.id,
            InvTagGroupDesc: newRow.description,
            InvTagGroupSort1: newRow.sort1,
            InvTagGroupSort2: newRow.sort2,
            InvTagServiceFlag: newRow.serviceFlag,
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
      } else {
        console.log("unknown tag type");
        return;
      }
      if (response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        console.log(response);
        let newRows = rows;
        if (tagType === "type") {
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].TypeID === newRow.id) {
              newRows[i].TypeID = newRow.id;
              newRows[i].TypeDesc = newRow.description;
              newRows[i].TypeSort = newRow.sort1;
              break;
            }
          }
        } else if (tagType === "category") {
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].CategoryID === newRow.id) {
              newRows[i].DeptID = newRow.department;
              newRows[i].TypeID = newRow.type;
              newRows[i].CategoryID = newRow.id;
              newRows[i].CategoryDesc = newRow.description;
              newRows[i].CategorySort = newRow.sort1;
              break;
            }
          }
        } else if (tagType === "group") {
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].GroupID === newRow.id) {
              newRows[i].DeptID = newRow.department;
              newRows[i].GroupID = newRow.id;
              newRows[i].GroupDesc = newRow.description;
              newRows[i].GroupSort1 = newRow.sort1;
              newRows[i].GroupSort2 = newRow.sort2;
              newRows[i].ServiceFlag = newRow.serviceFlag;
              break;
            }
          }
        }
        setRows(newRows);
        return response.data;
      }
    } catch (e) {
      console.log("GET call failed: ", e);
      setLoadingTags(false);
      return [];
    }
  }

  function LogTable() {
    const logRows = [
      {
        id: 1,
        date: "2025-01-01",
        change: "Updated",
        description: "Carrier AC",
      },
      {
        id: 2,
        date: "2025-01-02",
        change: "Deleted",
        description: "ProFlo Commode",
      },
      {
        id: 3,
        date: "2025-01-03",
        change: "Created",
        description: "Carrier Furnace",
      },
      {
        id: 4,
        date: "2025-01-04",
        change: "Updated",
        description: "ProFlo Faucet",
      },
    ];

    const columns = [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
      },
      {
        field: "id",
        headerName: "ID",
        flex: 0.5,
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
      },
      {
        field: "description",
        headerName: "Item Description",
        flex: 2,
      },
    ];
    return (
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={logRows}
          columns={columns}
          pageSize={5}
          autoHeight
          autoPageSize
          disableRowSelectionOnClick
          sx={{
            border: "none",
            minWidth: "100%",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-root": {
              fontSize: "1rem",
            },
          }}
        />
      </div>
    );
  }

  function EditableTable(tableType) {
    // Table Setup
    const tableContainerRef = React.useRef(null);
    React.useEffect(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0;
      }
    }, [page]);

    const typeColumns = [
      {
        field: "TypeID",
        headerName: "ID",
        flex: 0.5,
        editable: false,
      },
      {
        field: "TypeDesc",
        headerName: "Description",
        flex: 1,
        editable: true,
      },
      {
        field: "TypeSort",
        headerName: "Sort",
        type: "number",
        flex: 0.75,
        editable: true,
      },
    ];
    const categoryColumns = [
      {
        field: "CategoryID",
        headerName: "ID",
        flex: 0.5,
        editable: false,
      },
      {
        field: "CategoryDesc",
        headerName: "Description",
        flex: 1,
        editable: true,
      },
      {
        field: "DeptID",
        headerName: "Department",
        flex: 1,
        editable: true,
      },
      {
        field: "TypeID",
        headerName: "Type",
        flex: 1,
        editable: true,
      },
      {
        field: "CategorySort",
        headerName: "Sort",
        type: "number",
        flex: 0.75,
        editable: true,
      },
    ];
    const groupColumns = [
      {
        field: "id",
        headerName: "ID",
        flex: 0.5,
        editable: false,
      },
      {
        field: "GroupDesc",
        headerName: "Group",
        flex: 1,
        editable: true,
      },
      {
        field: "DeptID",
        headerName: "Department",
        flex: 1,
        editable: true,
      },
      {
        field: "GroupSort1",
        headerName: "Sort 1",
        type: "number",
        flex: 0.75,
        editable: true,
      },
      {
        field: "GroupSort2",
        headerName: "Sort 2",
        type: "number",
        flex: 0.75,
        editable: true,
      },
    ];

    const columns =
      tableType === "type"
        ? typeColumns
        : tableType === "group"
        ? groupColumns
        : tableType === "category"
        ? categoryColumns
        : [];

    return (
      <div
        ref={appContainerRef}
        style={{
          minHeight: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <Paper sx={{ width: "100%", mb: 2, border: "0px" }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ width: "100%", minHeight: "60vh", maxHeight: "60vh" }}
            >
              {loadingTags ||
              inventoryState?.filterArrays?.[0]?.relatedRows?.length <= 0 ? (
                <CasaIdleTableView
                  isLoading={loadingTags}
                  noResultText="No tags found."
                />
              ) : (
                <Table
                  stickyHeader
                  sx={{
                    minWidth: "100%",
                    borderCollapse: "collapse",
                  }}
                  size={"medium"}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.field}
                          sx={{
                            backgroundColor: "#000",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {column.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows?.length > 0 &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TableRow
                            hover
                            onClick={() => {
                              let newRowObj;
                              if (openTypes) {
                                newRowObj = {
                                  id: row.TypeID,
                                  description: row.TypeDesc,
                                  sort1: row.TypeSort,
                                };
                              } else if (openCategories) {
                                newRowObj = {
                                  id: row.CategoryID,
                                  description: row.CategoryDesc,
                                  sort1: row.CategorySort,
                                  department: row.DeptID,
                                  type: row.TypeID,
                                };
                              } else if (openGroups) {
                                newRowObj = {
                                  id: row.GroupID,
                                  description: row.GroupDesc,
                                  sort1: row.GroupSort1,
                                  sort2: row.GroupSort2,
                                  department: row.DeptID,
                                  serviceFlag: row.ServiceFlag,
                                };
                              } else {
                                console.log(
                                  "opening edit modal without defined tag type"
                                );
                                // do nothing
                              }
                              setNewRow(newRowObj);
                              setEditRow(row);
                              setOpenEditTag(true);
                            }}
                            key={row.id}
                          >
                            {columns.map((column) => (
                              <>
                                {column.headerName === "Type" && (
                                  <TableCell key={column.field}>
                                    {
                                      inventoryState?.filterArrays[1]?.relatedRows?.find(
                                        (tag) =>
                                          tag.LevelID === row[column.field]
                                      )?.LevelDesc
                                    }
                                  </TableCell>
                                )}
                                {column.headerName === "Department" && (
                                  <TableCell key={column.field}>
                                    {
                                      inventoryState?.filterArrays[0]?.relatedRows?.find(
                                        (tag) =>
                                          tag.LevelID === row[column.field]
                                      )?.LevelDesc
                                    }
                                  </TableCell>
                                )}
                                {column.headerName !== "Type" &&
                                  column.headerName !== "Department" && (
                                    <TableCell key={column.field}>
                                      {row[column.field]}
                                    </TableCell>
                                  )}
                              </>
                            ))}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </Paper>
        </>
      </div>
    );
  }

  function AdminDash() {
    useEffect(() => {}, []);
    return (
      <div
        ref={appContainerRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          marginTop: "-15vh",
          overflow: "hidden",
        }}
      >
        <>
          <Typography
            variant="h4"
            color="white"
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "4px",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Inventory Admin
          </Typography>
          <div style={{ display: "flex", marginTop: "1vh" }}>
            <CasaAppIcon
              title="Tags"
              width={casaWindowState.width}
              color="#08005C"
              altColor="#0D0078"
              type="action"
              casaClickHandle={() => {
                setOpenTags(true);
              }}
              // linkto={`/administration/search-employees/view/information`}
            />
            <CasaAppIcon
              title="Logs"
              width={casaWindowState.width}
              color="#B60080"
              altColor="#934747"
              type="action"
              casaClickHandle={() => {
                setOpenLogs(true);
              }}
              // linkto={`/administration/search-employees/view/permissions`}
            />
            <CasaAppIcon
              title="Reports"
              width={casaWindowState.width}
              color="#C18700"
              altColor="#BFA836"
              type="action"
              casaClickHandle={() => {
                setOpenReports(true);
              }}
              // linkto={`/administration/search-employees/view/reports`}
            />
          </div>
        </>
      </div>
    );
  }

  function TagDash() {
    return (
      <div
        ref={appContainerRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <>
          <div style={{ display: "flex", marginTop: "1vh" }}>
            <CasaAppIcon
              title="Type"
              width={casaWindowState.width}
              color="#08005C"
              altColor="#0D0078"
              type="action"
              casaClickHandle={() => {
                setPage(0);
                setOpenTypes(true);
                setLoadingTags(true);
                getTagsByTagType("type");
                setLoadingTags(false);
              }}
            />
            <CasaAppIcon
              title="Category"
              width={casaWindowState.width}
              color="#B60080"
              altColor="#934747"
              type="action"
              casaClickHandle={async () => {
                setPage(0);
                setOpenCategories(true);
                setLoadingTags(true);
                getTagsByTagType("category");
                const filters = await handleInventoryAPICall("getcatarrs", "");
                const allFilters = await handleInventoryAPICall("getcat", "");
                updateInventory({ filterArrays: filters, filters: allFilters });
                setLoadingTags(false);
              }}
            />
            <CasaAppIcon
              title="Group"
              width={casaWindowState.width}
              color="#C18700"
              altColor="#BFA836"
              type="action"
              casaClickHandle={async () => {
                setPage(0);
                setOpenGroups(true);
                setLoadingTags(true);
                getTagsByTagType("group");
                const filters = await handleInventoryAPICall("getcatarrs", "");
                const allFilters = await handleInventoryAPICall("getcat", "");
                updateInventory({ filterArrays: filters, filters: allFilters });
                setLoadingTags(false);
              }}
            />
          </div>
        </>
      </div>
    );
  }

  const getKey = () => {
    if (openCreateTag) {
      return 7;
    } else if (openEditTag) {
      return 6;
    } else if (openTypes) {
      return 0;
    } else if (openCategories) {
      return 1;
    } else if (openGroups) {
      return 2;
    } else if (openTags) {
      return 3;
    } else if (openLogs) {
      return 4;
    } else if (openReports) {
      return 5;
    } else {
      return 8;
    }
  };

  const compArr = [
    <div key="invTypes">
      <CasaConfigureComponent
        title="Type Tags"
        configType="inv-admin-type"
        createEnabled={true}
        editEnabled={false}
        openCreate={() => {
          // need 0s to ensure numerical input
          setCreateRow({ sort1: 0, sort2: 0 });
          setOpenCreateTag(true);
        }}
        closeModalFunction={() => {
          setOpenTypes(false);
        }}
        content={EditableTable("type")}
      />
    </div>,
    <div key="invCategories">
      <CasaConfigureComponent
        title="Category Tags"
        configType="inv-admin-category"
        createEnabled={true}
        editEnabled={false}
        openCreate={() => {
          // need 0s to ensure numerical input
          setCreateRow({ sort1: 0, sort2: 0 });
          setOpenCreateTag(true);
        }}
        closeModalFunction={() => {
          setOpenCategories(false);
        }}
        content={EditableTable("category")}
      />
    </div>,
    <div key="invGroups">
      <CasaConfigureComponent
        title="Group Tags"
        configType="inv-admin-group"
        createEnabled={true}
        editEnabled={false}
        openCreate={() => {
          // need this to ensure numerical input
          setCreateRow({ sort1: 0, sort2: 0 });
          setOpenCreateTag(true);
        }}
        closeModalFunction={() => {
          setOpenGroups(false);
        }}
        content={EditableTable("group")}
      />
    </div>,
    <div key="invTags">
      {
        <CasaConfigureComponent
          title="Manage Tags"
          closeModalFunction={() => {
            setOpenTags(false);
          }}
          editEnabled={false}
          content={TagDash()}
        />
      }
    </div>,
    <div key="invLogs">
      {
        <CasaConfigureComponent
          title="Inventory Logs"
          closeModalFunction={() => {
            setOpenLogs(false);
          }}
          editEnabled={false}
          content={LogTable()}
        />
      }
    </div>,
    <div key="invReports">
      <CasaConfigureComponent
        title="Inventory Reports"
        closeModalFunction={() => {
          setOpenReports(false);
        }}
        editEnabled={false}
      />
    </div>,
    <div key="editTag">
      {
        <CasaConfigureComponent
          title="Edit Tag"
          configType="inv-admin-tag-edit"
          editEnabled={true}
          handleSave={() => {
            if (openTypes) {
              updateTag("type");
            } else if (openCategories) {
              updateTag("category");
            } else if (openGroups) {
              updateTag("group");
            }
          }}
          closeModalFunction={() => {
            setOpenEditTag(false);
          }}
          newRow={newRow}
          setNewRow={setNewRow}
          openTypes={openTypes}
          openCategories={openCategories}
          openGroups={openGroups}
          inventoryState={inventoryState}
          userState={userState}
        />
      }
    </div>,
    <div key="createTag">
      <CasaConfigureComponent
        title="Create Tag"
        configType="inv-admin-tag-create"
        editEnabled={false}
        createNewTag={createNewTag}
        closeModalFunction={() => {
          setOpenCreateTag(false);
        }}
        newRow={createRow}
        setNewRow={setCreateRow}
        openTypes={openTypes}
        openCategories={openCategories}
        openGroups={openGroups}
        inventoryState={inventoryState}
        userState={userState}
        loadingTags={loadingTags}
      />
    </div>,
    <div key="adminDash">{AdminDash()}</div>,
  ];

  return (
    <div>
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-inventory"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default InventoryAdmin;
