// InventoryFolder.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const AdministrationFolder = (props) => {
  const iconsData = [
    {
      title: "Time Clock",
      color: "red",
      altColor: "orange",
      linkto: "/administration/timeclock",
    },
    {
      title: "Employee",
      color: "green",
      altColor: "teal",
      linkto: "/administration/search-employees",
    },
  ];

  return <CasaAppFolder title="Administration" iconsData={iconsData} />;
};

export default AdministrationFolder;
