// Home.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const Home = (props) => {
  const iconsData = [
    // {
    //   title: "Inventory",
    //   color: "blue",
    //   altColor: "purple",
    //   linkto: "/inventory/",
    // },
    // {
    //   title: "Time Clock",
    //   color: "red",
    //   altColor: "orange",
    //   linkto: "/timeclock",
    // },
    // {
    //   title: "Administration",
    //   color: "black",
    //   altColor: "brown",
    //   linkto: "/administration",
    // },
    // {
    //   title: "Purchasing",
    //   color: "#0b210d",
    //   altColor: "#0a2e19",
    //   linkto: "/purchasing",
    // },
    {
      title: "CasaGPT",
      color: "green",
      altColor: "green",
      linkto: "/casagpt",
    },
    {
      title: "Dashboard",
      color: "black",
      altColor: "brown",
      linkto: "/dashboard",
    },
    {
      title: "Messages",
      color: "blue",
      altColor: "purple",
      linkto: "/messages",
    },
    {
      title: "Updates",
      color: "#00999c",
      altColor: "#006946",
      linkto: "/updates",
    },
    {
      title: "Settings",
      color: "#333333",
      altColor: "#808080",
      linkto: "/settings",
    },
  ];

  return <CasaAppFolder title="Home" iconsData={iconsData} />;
};

export default Home;
