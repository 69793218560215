import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useTheme,
} from "@mui/material";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";

const Dashboard = (props) => {
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const [dashData, setDashData] = useState({});
  const [loading, setLoading] = useState({});
  const theme = useTheme();

  useEffect(() => {
    for (let i = 1; i <= 13; i++) {
      getDashboardData(i);
    }
  }, [userState.department]);

  async function getDashboardData(dashType) {
    setLoading((prev) => ({ ...prev, [dashType]: true }));
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);

    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetDashboardDaily",
          DashboardType: dashType,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (response?.data) {
        setDashData((prev) => ({
          ...prev,
          [dashType]: response.data,
        }));
      }
    } catch (e) {
      console.error("Error retrieving dashboard data.");
    }
    setLoading((prev) => ({ ...prev, [dashType]: false }));
  }

  const metrics = [
    { label: "Total Job Stages Scheduled For Today", key: 1 },
    { label: "Total Job Stages Scheduled For Next Five Days", key: 2 },
    { label: "Total Open Jobs Older Than Seven Days", key: 3 },
    { label: "Total Open Job Stages By Superintendent", key: 4 },
    {
      label: "Total Dollar Amount Of Unbilled Misc Jobs",
      key: 11,
      money: true,
    },
    { label: "Total Job Stages By Superintendent For Today", key: 5 },
    { label: "Total Open Misc Jobs", key: 9 },
    { label: "Total Open Misc Jobs By Crew", key: 6 },
    { label: "Total Misc Jobs By Crew For Today", key: 7 },
    { label: "Inspection Results For Previous Five Days", key: 8 },
    { label: "Total Open Misc Jobs Older Than Two Days", key: 10 },
    // { label: "Total Service Warranty Jobs For Today", key: 12 },
    {
      label: "Total Backcharge Amount For Previous Week",
      key: 13,
      money: true,
    },
  ];

  const serviceMetrics = [
    { label: "Total Job Stages Scheduled For Today", key: 1 },
    { label: "Total Job Stages Scheduled For Next Five Days", key: 2 },
    { label: "Total Open Jobs Older Than Seven Days", key: 3 },
    { label: "Total Open Job Stages By Tech", key: 4 },
    {
      label: "Total Dollar Amount Of Unbilled Misc Jobs",
      key: 11,
      money: true,
    },
    { label: "Total Job Stages By Tech For Today", key: 5 },
    { label: "Total Open Misc Jobs", key: 9 },
    { label: "Total Open Misc Jobs By Tech", key: 6 },
    { label: "Total Misc Jobs By Tech For Today", key: 7 },
    { label: "Total Open Misc Jobs Older Than Two Days", key: 10 },
    { label: "Total Service Warranty Jobs For Today", key: 12 },
    {
      label: "Total Backcharge Amount For Previous Week",
      key: 13,
      money: true,
    },
  ];

  const formatMoney = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const renderData = (data, metric) => {
    if (Array.isArray(data) && data.length > 0) {
      const keys = Object.keys(data[0]);

      if (keys.length === 1) {
        // if there's only one column, display its value as typography
        return (
          <Typography
            variant="h1"
            sx={{
              fontSize: "calc(5rem)", // Adjusts dynamically based on container width
              width: "100%", // Ensures the Typography takes full width of its container
              whiteSpace: "nowrap", // Prevents text from wrapping
              //   overflow: "hidden", // Hides overflow if text doesn't fit
              display: "inline-block", // Ensures proper text scaling without breaking the layout
            }}
          >
            {metric.money
              ? formatMoney(data.map((row) => row[keys[0]]).join(", "))
              : isNaN(data.map((row) => row[keys[0]]).join(", "))
              ? data.map((row) => row[keys[0]]).join(", ")
              : parseInt(
                  data.map((row) => row[keys[0]]).join(", ")
                ).toLocaleString()}
          </Typography>
        );
      }

      return (
        <TableContainer sx={{ height: 275 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {keys.map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {keys.map((key) => (
                    <TableCell key={key}>{row[key]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <Typography sx={{ fontSize: "calc(4rem)" }}>{"No Data"}</Typography>;
  };

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning, " + userState.name.split(" ")[0] + ".";
    if (hour < 18)
      return "Good Afternoon, " + userState.name.split(" ")[0] + ".";
    return "Good Evening, " + userState.name.split(" ")[0] + ".";
  };

  return (
    <Grid container spacing={3} sx={{ mb: "25px" }}>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center", padding: 4 }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: "2.5rem",
              letterSpacing: 1,
              marginBottom: 1,
            }}
          >
            {userState.department} Dashboard
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.5rem",
              letterSpacing: 0.5,
            }}
          >
            {getGreeting()}
          </Typography>
        </Box>
      </Grid>
      {userState.department === "Service"
        ? serviceMetrics.map((metric) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={metric.key}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                {/* Title */}
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
                >
                  <b>{metric.label}</b>
                </Typography>
              </Box>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 315,
                  overflow: "auto",
                }}
              >
                {loading[metric.key] ? (
                  <CircularProgress />
                ) : (
                  renderData(dashData[metric.key], metric)
                )}
              </Paper>
            </Grid>
          ))
        : metrics.map((metric) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={metric.key}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                {/* Title */}
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
                >
                  <b>{metric.label}</b>
                </Typography>
              </Box>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 315,
                  overflow: "auto",
                }}
              >
                {loading[metric.key] ? (
                  <CircularProgress />
                ) : (
                  renderData(dashData[metric.key], metric)
                )}
              </Paper>
            </Grid>
          ))}
    </Grid>
  );
};

export default Dashboard;
