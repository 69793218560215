// InventoryFolder.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const Estimating = (props) => {
  const iconsData = [
    {
      title: "Customers",
      color: "blue",
      altColor: "violet",
      linkto: "/estimating/search-customers",
    },
  ];

  return <CasaAppFolder title="Estimating" iconsData={iconsData} />;
};

export default Estimating;
