import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import CasaCloseButton from "../CasaCloseButton/CasaCloseButton";
import { useAPIContext } from "../../../contexts/APIContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";

const CasaSearchComponent = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [searchParams] = useSearchParams();
  const { administrationState, updateAdministration } =
    useAdministrationContext();

  const [employeesList, setEmployeesList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const { APIState } = useAPIContext();
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const { userState, updateUserState } = useUserStateContext();

  async function getUsers() {
    setLoadingEmployees(true);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetUsers",
          ActiveUser: 1,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (response.data && response.data.length > 0) {
        setEmployeesList(response.data);
      }
      setLoadingEmployees(false);
      return response.data;
    } catch (e) {}
  }

  const processSearchReq = () => {
    // Update URL params with selected employee name
    navigate(props.onClickURL);
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (props.type === "employee") {
      updateAdministration({ employee: newValue });
    } else if (props.type === "customer") {
      updateAdministration({ customer: newValue });
    }
  };

  useEffect(() => {
    updateAdministration({ employee: {} });
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor,
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>{props.title}</b>
            </div>
          </Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "2em",
            paddingLeft: "2em",
            paddingBottom: "1em",
            paddingTop: "0.5em",
            overflow: "auto",
          }}
        >
          <center>
            <div style={{ padding: "20px" }}>
              <Typography
                variant="findSearchSubtitle"
                component="div"
                align="center"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {props.subtitle}
              </Typography>
              <Autocomplete
                options={props.type === "employee" ? employeesList : []}
                disabled={loadingEmployees}
                groupBy={(option) => option.FullName.substring(0, 1)}
                id="tags-outlined"
                onChange={handleAutocompleteChange}
                getOptionLabel={(option) => option.FullName}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={loadingEmployees ? "Loading..." : "Add Member(s)"}
                  />
                )}
                style={{
                  marginTop: "10px",
                  width: "70%",
                }}
              />
              {/* <Autocomplete
                id="search-employees-text"
                freeSolo
                groupBy={(option) => option.FullName.substring(0, 1)}
                value={
                  props.type === "employee"
                    ? administrationState.employeeName
                    : administrationState.customerName
                }
                onChange={handleChange}
                options={
                  props.type === "employee"
                    ? employeesList
                    : administrationState.customerList
                }
                renderInput={(params) => (
                  <TextField {...params} label={props.placeholder} />
                )}
                style={{
                  marginTop: "10px",
                  width: "70%",
                }}
              /> */}
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: "-10px",
                }}
                onClick={processSearchReq}
                disabled={
                  props.type === "employee"
                    ? !administrationState.employee?.FullName
                    : !administrationState.customer?.FullName
                }
              >
                {props.buttonText}
              </Button>
            </div>
          </center>
        </Box>
      </Paper>
    </div>
  );
};

export default CasaSearchComponent;
