// Backorders.js
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme, styled } from "@mui/material/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";

// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import "./Purchasing.css"; // Import CSS for transitions

import axios from "axios";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import CasaConfigureComponent from "../../Resuable/CasaConfigureComponent/CasaConfigureComponent";
import CasaDetailDashComponent from "../../Resuable/CasaDetailDashComponent/CasaDetailDashComponent";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const serviceHeadCells = [
  {
    id: "PoStatusName",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "PoNumber",
    numeric: false,
    disablePadding: false,
    label: "Order #",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "VendorName",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "PoDeptName",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "PoDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

const headCells = [
  {
    id: "PoStatusName",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "PoNumber",
    numeric: false,
    disablePadding: false,
    label: "Order #",
  },
  {
    id: "VendorName",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "PoDeptName",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  {
    id: "PoDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

function EnhancedTableHead(props) {
  const { userState, updateUserState } = useUserStateContext();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          {/* <span>-</span> */}
        </TableCell>
        {userState.department === "Service"
          ? serviceHeadCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  "&:hover": {
                    color: "#fff", // Change this to the desired hover color
                  },
                  "&:focus": {
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "white",
                    },
                    "&.MuiTableSortLabel-root:hover": {
                      color: "white",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    },
                    "&.Mui-active": {
                      color: "white",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "white !important",
                    },
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  "&:hover": {
                    color: "#fff", // Change this to the desired hover color
                  },
                  "&:focus": {
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "white",
                    },
                    "&.MuiTableSortLabel-root:hover": {
                      color: "white",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    },
                    "&.Mui-active": {
                      color: "white",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "white !important",
                    },
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ThickInventoryHeader({
  name,
  rows,
  setRows,
  status,
  setStatus,
  handleAddMode,
  setFilteredRows,
  setPage,
  setIsLoading,
  getPoList,
  backButton,
  keyword,
  setKeyword,
}) {
  const selectedColumn = "PoID";
  const { updateInventory } = useInventoryContext();
  const [incompleteSearchOpen, setIncompleteSearchOpen] = React.useState(false);

  React.useEffect(() => {
    const filteredRows = rows?.filter((row) => {
      const value = row[selectedColumn].toString().toLowerCase();
      return value;
    });
    setPage(0);
    setFilteredRows(filteredRows);
  }, [selectedColumn, rows, setFilteredRows, setPage]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size

  async function searchParts() {
    if (keyword === "") {
      setIncompleteSearchOpen(true);
      return;
    }
    setIsLoading(true);
    const data = await getPoList();
    setRows(data);
    setIsLoading(false);
  }

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      <CasaCloseButton handleClose={backButton} />
      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: isLargeScreen ? "space-between" : "center", // Adjust justify content based on screen size
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            padding: "1vh",
          }}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          <b>Backorders</b>
        </Typography>
        {/* {isLargeScreen && ( // Render filter and search field only on large screens */}
        <>
          {/* <Select
            id="status-filter-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            sx={{
              minWidth: "150px",
              margin: "8px",
            }}
          >
            <MenuItem value={0}>
              <em>All</em>
            </MenuItem>
            <MenuItem value={1}>Drafts</MenuItem>
            <MenuItem value={2}>Pending</MenuItem>
            <MenuItem value={3}>Completed</MenuItem>
          </Select> */}
          <TextField
            id="search-filter-textfield"
            label="Keywords"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchParts();
              }
            }}
            sx={{
              minWidth: "15em",
              paddingTop: "0.5vh",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={searchParts}
            sx={{ minWidth: "100px", mt: "10px", ml: "8px" }}
          >
            Search
          </Button>
        </>
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        open={incompleteSearchOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Empty Search</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter a search query into the search bar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIncompleteSearchOpen(false);
            }}
            sx={{ color: theme.palette.primary.main }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default function Backorders(props) {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("description");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [status, setStatus] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [editRow, setEditRow] = React.useState(null);
  const [addMode, setAddMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const { userState, updateUserState } = useUserStateContext();

  const { inventoryState, updateInventory } = useInventoryContext();

  async function getPoList() {
    setIsLoading(true);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);

    try {
      const response = await axios.get(APIState.PurchAPI, {
        params: {
          PurchFunc: "GetPoList",
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          PoKeywords: keyword,
          PoStatusID: status,
          PoBackOrderFlag: 1,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        console.log(response);
        return response.data;
      }
    } catch (e) {
      console.error("Error retrieving dashboard data.");
    }
    setIsLoading(false);
  }

  function setupSearch() {
    setKeyword("");
    setRows([]);
  }

  React.useEffect(() => {
    setupSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  async function saveData() {
    console.log("saving data...");
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "SetInvItem",
          ItemID: editRow.InvID,
          Level1Parm: editRow.InvDept,
          Level2Parm: editRow.InvType,
          InvDescription: editRow.InvDescription,
          InvPart: editRow.InvPart,
          InvCost: editRow.InvCost,
          InvUnit: editRow.InvUnit,
          InvQuantity: editRow.InvQuantity,
          InvNotes: editRow.InvNotes,
          InvCategory: editRow.InvCategory,
          InvGroup: editRow.InvGroup,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  function handleEditRow(data) {
    setEditMode(false);
    if (data) setEditRow(data);
    console.log(data);
  }

  function handleEditMode() {
    setEditMode(!editMode);
    setAddMode(false);
  }

  function handleAddMode() {
    setAddMode(!addMode);
    setEditMode(false);
  }

  function handleAdd() {
    const description = document.getElementById("description-textfield").value;
    const mfgNum = document.getElementById("mfg-num-textfield").value;
    const unit = document.getElementById("unit-textfield").value;
    const cost = document.getElementById("cost-textfield").value;
    const qty = document.getElementById("qty-textfield").value;
    const notes = document.getElementById("notes-textfield").value;

    const newRow = {
      id: rows?.length + 1,
      description,
      mfgNum,
      unit,
      cost,
      qty,
      notes,
    };

    setRows((prevRows) => {
      const updatedRows = [...prevRows, newRow];
      return updatedRows;
    });
    handleAddMode();
  }

  function handleSave() {
    // add POST api call here later to CasaDB
    const updatedRows = [...rows];
    const description = document.getElementById("description-textfield").value;
    const mfgNum = document.getElementById("mfg-num-textfield").value;
    const unit = document.getElementById("unit-textfield").value;
    const cost = document.getElementById("cost-textfield").value;
    const qty = document.getElementById("qty-textfield").value;
    const notes = document.getElementById("notes-textfield").value;

    editRow.InvDescription = description;
    editRow.InvPart = mfgNum;
    editRow.InvUnit = unit;
    editRow.InvCost = cost;
    editRow.InvQuantity = qty;
    editRow.InvNotes = notes;

    const index = updatedRows?.findIndex(
      (item) => item.InvID === editRow.InvID
    );
    updatedRows[index] = editRow;
    saveData();
    setRows(updatedRows);
    handleEditRow(null);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  function handleClose() {
    setAddMode(false);
    setEditMode(false);
    setEditRow(null);
  }

  function addModal() {
    return (
      <>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            padding: "1rem",
            position: "relative",
            // backgroundColor: "inherit",
            borderRadius: "8px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              left: "0.5rem",
              top: "0.5rem",
              color: "#fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <span
            style={{
              fontSize: "1.5em",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Add Item
          </span>

          {/* <IconButton
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              color: "#fff",
              backgroundColor: theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor: theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={editMode ? handleSave  : handleEditMode}
          >
            {editMode ? <SaveIcon />  : <EditIcon />}
          </IconButton> */}
          <br />
          <br />
          <Grid container spacing={2}>
            {/* On smaller screens, display as 4x1 grid */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="description-textfield"
                label="Description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="mfg-num-textfield" label="Part #" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="unit-textfield" label="Unit" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="cost-textfield" label="Cost" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="qty-textfield" label="Qty" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth id="notes-textfield" label="Notes" />
            </Grid>
          </Grid>
          <br />
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Add
          </Button>
        </Paper>
      </>
    );
  }

  // View Entry Row Styling
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    background: `linear-gradient(45deg, ${theme.palette.customColors.darkerPrimaryColor} 30%, ${theme.palette.primary.main} 100%)`,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    // "&:first-of-type": {
    //   borderTopLeftRadius: "8px",
    //   borderBottomLeftRadius: "8px",
    // },
    // "&:last-of-type": {
    //   borderTopRightRadius: "8px",
    //   borderBottomRightRadius: "8px",
    // },
  }));

  const PlainTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    // background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.customColors.lighterPrimaryColor} 100%)`,
    // color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  }));

  const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    // padding: "2px",
    // borderRadius: "8px",
    // boxShadow: "2px 3px 5px 2px rgba(255, 105, 135, .3)",
  }));

  const TableStyled = styled(Table)(({ theme }) => ({
    // margin: "auto",
    // minWidth: 650,
    // borderCollapse: "separate",
    // borderSpacing: "0 10px",
  }));

  function editModeView() {
    return (
      <Grid container spacing={1}>
        {/* On smaller screens, display as 4x1 grid */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="description-textfield"
            label="Description"
            defaultValue={editRow.InvDescription}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="mfg-num-textfield"
            label="Part #"
            defaultValue={editRow.InvPart}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="unit-textfield"
            label="Unit"
            defaultValue={editRow.InvUnit}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="cost-textfield"
            label="Cost"
            defaultValue={editRow.InvCost}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="qty-textfield"
            label="Qty"
            defaultValue={editRow.InvQuantity}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="notes-textfield"
            label="Notes"
            defaultValue={editRow.InvNotes}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function viewModeView() {
    return (
      <TableContainerStyled>
        <TableStyled>
          <TableBody>
            <TableRow key="description">
              <StyledTableCell>
                <Typography>
                  <b>Description</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvDescription}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="mfgNum">
              <StyledTableCell>
                <Typography>
                  <b>Part #</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvPart}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="type">
              <StyledTableCell>
                <Typography>
                  <b>Type</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>
                  {
                    inventoryState.filterArrays[2]?.relatedRows?.find(
                      (item) => item.LevelID === editRow.InvType
                    )?.LevelDesc
                  }
                </Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="category">
              <StyledTableCell>
                <Typography>
                  <b>Category</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>
                  {
                    inventoryState.filterArrays[0]?.relatedRows?.find(
                      (item) => item.LevelID === editRow.InvCategory
                    )?.LevelDesc
                  }
                </Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="group">
              <StyledTableCell>
                <Typography>
                  <b>Group</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>
                  {
                    inventoryState.filterArrays[1]?.relatedRows?.find(
                      (item) => item.LevelID === editRow.InvGroup
                    )?.LevelDesc
                  }
                </Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="unit">
              <StyledTableCell>
                <Typography>
                  <b>Unit</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvUnit}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="cost">
              <StyledTableCell>
                <Typography>
                  <b>Cost</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvCost}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="qty">
              <StyledTableCell>
                <Typography>
                  <b>Qty</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvQuantity}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow key="notes">
              <StyledTableCell>
                <Typography>
                  <b>Notes</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.InvNotes}</Typography>
              </PlainTableCell>
            </TableRow>
          </TableBody>
        </TableStyled>
      </TableContainerStyled>
    );
  }

  const tableContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [page]);

  const getChipColor = (status) => {
    switch (status) {
      case "COMPLETED":
        return "success";
      case "PENDING":
        return "warning";
      default:
        return "default";
    }
  };

  function mainTable(tableContainerRef) {
    return (
      <>
        <ThickInventoryHeader
          name={props.name}
          keyword={keyword}
          setKeyword={setKeyword}
          rows={rows}
          setRows={setRows}
          status={status}
          setStatus={setStatus}
          handleAddMode={handleAddMode}
          setFilteredRows={setFilteredRows}
          setPage={setPage}
          setIsLoading={setIsLoading}
          getPoList={getPoList}
          backButton={props.backButton}
        />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            >
              {!isLoading && rows?.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  <TableBody>
                    {visibleRows?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.InvID}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => handleEditRow(row)}
                        >
                          <TableCell>
                            {/* <InfoOutlinedIcon sx={{ color: "#555" }} /> */}
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={row.PoStatusName.toUpperCase()}
                              color={getChipColor(
                                row.PoStatusName.toUpperCase()
                              )}
                              sx={{ fontWeight: "bold" }}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: "235px" }}>
                            {row.PoNumber}
                          </TableCell>
                          {userState.department === "Service" ? (
                            <TableCell>{row.Address}</TableCell>
                          ) : null}
                          <TableCell>{row.VendorName}</TableCell>
                          <TableCell>{row.PoDeptName}</TableCell>
                          <TableCell>
                            {new Date(
                              new Date(row?.PoDate)?.setDate(
                                new Date(row?.PoDate)?.getDate() + 1
                              )
                            )?.toLocaleDateString()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <CasaIdleTableView
                  isLoading={isLoading}
                  noResultText="No results. Try searching by Order Number or Vendor."
                />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  const getKey = () => {
    if (addMode) return 0; // Assuming index 0 is for addModal
    if (editRow) return 1; // Assuming index 1 is for dashView
    return 2; // Assuming index 2 is for mainTable
  };

  const compArr = [
    <div key="addModal">{addModal()}</div>,
    <div key="dashView">
      <CasaDetailDashComponent
        title={"Purchase Order #" + editRow?.PoNumber}
        dashtype="purchase-backorder"
        editMode={editMode}
        editRow={editRow}
        closeModalFunction={handleClose}
      />
    </div>,
    <div key="mainTable">{mainTable(tableContainerRef)}</div>,
  ];

  return (
    <div>
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-purchasing"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
