// PartLocator.js
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import axios from "axios";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { visuallyHidden } from "@mui/utils";
import CasaIdleTableView from "../../Resuable/CasaIdleTableView/CasaIdleTableView";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";

const headCells = [
  {
    id: "InvDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "InvPart",
    numeric: false,
    disablePadding: false,
    label: "Part #",
  },
  {
    id: "InvUnit",
    numeric: false,
    disablePadding: false,
    label: "Unit",
  },
  {
    id: "InvQuantity",
    numeric: false,
    disablePadding: false,
    label: "Qty",
  },
  {
    id: "InvCost",
    numeric: false,
    disablePadding: false,
    label: "Cost",
  },
  {
    id: "InvNotes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ThickInventoryHeader({
  name,
  rows,
  setRows,
  setFilteredRows,
  setPage,
  setIsLoading,
  backButton,
}) {
  const [selectedColumn] = React.useState("InvDescription");
  const { userState, updateUserState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const { APIState } = useAPIContext();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  React.useEffect(() => {
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  React.useEffect(() => {
    const filteredRows = rows.filter((row) => {
      const value = row[selectedColumn]?.toString().toLowerCase();
      return value;
    });
    setPage(0);
    setFilteredRows(filteredRows);
  }, [selectedColumn, rows, setFilteredRows, setPage]);

  async function searchByKeyword(keyword) {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    if (keyword === "") {
      setIsLoading(false);
      setRows([]);
      return;
    }
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      setIsLoading(true);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItem",
          InvMode: 0,
          ItemID: 0,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          Level2Parm: 0,
          Level3Parm: 0,
          Level4Parm: 0,
          Keywords: inventoryState.addToAsmSearchKeyword,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, error, or empty/null response from API.");
        console.log(rows);
        setRows([]);
        setIsLoading(false);
        return [];
      } else {
        console.log(response);
        setRows(response.data);
        setIsLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e); // Use console.error for errors
      return []; // Explicitly return null or handle as necessary
    }
  }

  const getFilterOptions = async () => {
    if (
      !inventoryState.newItemGroup ||
      inventoryState.newItemGroup === "" ||
      inventoryState.assemblyDepartmentIndex !==
        userState.getDepartmentIndex(userState.department)
    ) {
      console.log("running getFilterOptions for filters");
      // filters enabled, no group selected yet
      setIsLoading(true);
      setRows([]);
      setFiltersLoaded(false);
      updateInventory({
        newItemCategory: "",
        newItemType: "",
        newItemGroup: "",
        newItemTypeOptions: [],
        newItemCategoryOptions: [],
        newItemGroupOptions: [],
        reviewDepartmentIndex: userState.getDepartmentIndex(
          userState.department
        ),
      });
      const data = await getFilters();
      // Next, get the ID of the department you're looking for
      const dept =
        data[0]?.relatedRows[userState.getDepartmentIndex(userState.department)]
          ?.LevelID;
      // Finally, retrieve the options for that ID to get the correct data
      const options = data.find((filter) => filter.parentId === dept);
      updateInventory({
        filters: data,
        newItemTypeOptions: options.relatedRows,
      });
      setFiltersLoaded(true);
      setIsLoading(false);
    } else {
      setFiltersLoaded(true);
      if (!inventoryState.showFilterSearchAddToAsm) {
        // filters disabled, search enabled
        console.log(inventoryState.addToAsmSearchKeyword);
        if (!inventoryState.addToAsmSearchKeyword) {
          // no existing keyword typed in
        } else {
          // existing keyword typed in
          searchByKeyword(inventoryState.addToAsmSearchKeyword);
        }
      } else {
        getDataFromFilters(inventoryState.newItemGroup);
      }
    }
  };

  async function searchParts(value) {
    if (inventoryState.newItemType === "") {
      alert("Please select a type.");
      return;
    } else if (inventoryState.newItemCategory === "" || !value) {
      alert("Please select a category.");
      return;
    }
    setIsLoading(true);
    const data = await getDataFromFilters(value);
    setRows(data);
    setIsLoading(false);
  }

  async function getDataFromFilters(group) {
    try {
      setIsLoading(true);
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvItem",
          InvMode: 2,
          Level1Parm: parseInt(
            userState.getDepartmentIndex(userState.department) + 1
          ),
          Level2Parm: inventoryState.newItemType.split("-")[1],
          Level3Parm: inventoryState.newItemCategory.split("-")[1],
          Level4Parm: group.split("-")[1],
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (
        !Array.isArray(response.data) ||
        response.data === null ||
        response.data[0]?.StatusCode === "ERROR"
      ) {
        console.log("No results, or empty/null response from API.");
        setRows([]);
        setFiltersLoaded(true);
        setIsLoading(false);
        return [];
      } else {
        setRows(response.data);
        setIsLoading(false);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setIsLoading(false);
      return [];
    }
  }

  async function getFilters() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 0,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        return [];
      } else {
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        newItemType: value,
        newItemCategory: "",
        newItemGroup: "",
      });
      setRows([]);
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ newItemCategoryOptions: results });
      }
      updateInventory({ newItemGroupOptions: [] });
    } else if (type === "category") {
      setRows([]);
      updateInventory({
        newItemCategory: value,
        newItemGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ newItemGroupOptions: results });
      } else {
        updateInventory({ newItemGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        newItemGroup: value,
      });
    }
  }

  function getFilterDropdowns() {
    return (
      <>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          <InputLabel id="type-label">
            {!filtersLoaded ? "Loading..." : "Type"}
          </InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={inventoryState.newItemType}
            onChange={(event) => {
              handleUpdateInventory("type", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Type"}
            disabled={!filtersLoaded}
          >
            {inventoryState.newItemTypeOptions?.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          <InputLabel id="category-label">
            {!filtersLoaded ? "Loading..." : "Category"}
          </InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={inventoryState.newItemCategory}
            disabled={
              !inventoryState.newItemType ||
              inventoryState.newItemType === "" ||
              !filtersLoaded
            }
            onChange={(event) => {
              handleUpdateInventory("category", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Category"}
          >
            {inventoryState.newItemCategoryOptions?.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            marginY: "8px",
            marginX: { xs: "0px", sm: "3px", md: "4px" },
          }}
        >
          {/* Add the InputLabel component */}
          <InputLabel id="group-label">
            {!filtersLoaded ? "Loading..." : "Group"}
          </InputLabel>

          <Select
            labelId="group-label"
            id="group"
            value={inventoryState.newItemGroup}
            disabled={
              !inventoryState.newItemCategory ||
              inventoryState.newItemCategory === "" ||
              !filtersLoaded
            }
            onChange={(event) => {
              handleUpdateInventory("group", event.target.value);
              if (event.target.value) {
                searchParts(event.target.value);
              }
            }}
            label={!filtersLoaded ? "Loading..." : "Group"}
          >
            {inventoryState.newItemGroupOptions?.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }

  function getSearchBar() {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          value={inventoryState.addToAsmSearchKeyword}
          onChange={(e) => {
            updateInventory({ addToAsmSearchKeyword: e.target.value });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchByKeyword(inventoryState.addToAsmSearchKeyword);
            }
          }}
          variant="outlined"
          placeholder="Search..."
          fullWidth
          sx={{ flex: 1, width: "900px", mt: "10px", mb: "10px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: "12px", mb: "12px" }}
          onClick={() => {
            searchByKeyword(inventoryState.addToAsmSearchKeyword);
          }}
        >
          Search
        </Button>
      </Box>
    );
  }

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      <CasaCloseButton handleClose={backButton} />
      <Typography
        sx={{
          // textDecoration: "underline",
          paddingTop: "1vh",
          textAlign: "center",
          justifyContent: "center",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          margin: "14px",
        }}
        variant="filterViewTitle"
        id="tableTitle"
        component="div"
      >
        {/* Review Inventory */}
        Add to {inventoryState.assembly.InvDescription}
        <IconButton
          onClick={() => {
            //refresh view based on selected filters/keyword
            if (!inventoryState.showFilterSearchAddToAsm) {
              // filters showing
              getDataFromFilters(inventoryState.newItemGroup);
            } else {
              // filters hidden, text search showing
              searchByKeyword(inventoryState.addToAsmSearchKeyword);
            }
            updateInventory({
              showFilterSearchAddToAsm:
                !inventoryState.showFilterSearchAddToAsm,
            });
          }}
          sx={{ ml: 1 }}
        >
          {inventoryState.showFilterSearchAddToAsm ? (
            <SearchIcon />
          ) : (
            <FilterListIcon />
          )}
        </IconButton>
      </Typography>
      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: "center", // isLargeScreen ? "space-between" : "center"
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        {inventoryState.showFilterSearchAddToAsm && getFilterDropdowns()}
        {!inventoryState.showFilterSearchAddToAsm && getSearchBar()}
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </div>
    </Paper>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          <span>-</span>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:hover": {
                color: "#fff", // Change this to the desired hover color
              },
              "&:focus": {
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },
                "&.MuiTableSortLabel-root:hover": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "&.Mui-active": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "white !important",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const PartLocator = (props) => {
  const [results, setResults] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("description");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState();
  const { inventoryState, updateInventory } = useInventoryContext();
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  async function handleAddItem(item) {
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    updateInventory({ showNewItem: true });
    try {
      const response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "SetInvAssemblyItem",
          AsmblyID: inventoryState.assembly.InvID,
          AsmblyPartID: item.InvID,
          InvQuantity: 1,
          InvStageID: 0,
          AsmblyEquipFlag: false,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      // updateInventory({ newItem: item });
      props.backButton();
      props.setRows((prevRows) => [...prevRows, item]);
      console.log(inventoryState.assembly);
      console.log(item);
      // navigate("/inventory/assembly/view");
      return response.data;
    } catch (e) {
      console.error("API Error on SetInvAssemblyItem.");
      return [];
    }
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  const tableContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [page]);

  function resultsComponent() {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer
              ref={tableContainerRef}
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            >
              {!isLoading && rows.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.InvID}
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => handleAddItem(row)}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Add Item
                            </Button>
                          </TableCell>
                          <TableCell style={{ minWidth: "235px" }}>
                            {row.InvDescription}
                          </TableCell>
                          <TableCell>{row.InvPart}</TableCell>
                          <TableCell>{row.InvUnit}</TableCell>
                          <TableCell>{row.InvQuantity}</TableCell>
                          <TableCell>{row.InvCost}</TableCell>
                          <TableCell>{row.InvNotes}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <CasaIdleTableView
                  isLoading={isLoading}
                  noResultText="No results. Search for items using the dropdowns or search bar located above."
                />
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  return (
    <div>
      <ThickInventoryHeader
        name={props.name}
        rows={rows}
        setRows={setRows}
        setFilteredRows={setFilteredRows}
        setPage={setPage}
        setIsLoading={setIsLoading}
        backButton={props.backButton}
      />
      <Paper sx={{ minHeight: "40vh" }}>{resultsComponent()}</Paper>
    </div>
  );
};

export default PartLocator;
