import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import CasaAppIcon from "../../Resuable/CasaAppIcon/CasaAppIcon";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useAPIContext } from "../../../contexts/APIContext";
import CasaLoadingComponent from "../../Resuable/CasaLoadingComponent/CasaLoadingComponent";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import CasaConfigureComponent from "../../Resuable/CasaConfigureComponent/CasaConfigureComponent";
import { useUserStateContext } from "../../../contexts/UserStateContext";

const ViewEmployee = (props) => {
  const { administrationState } = useAdministrationContext(); //updateAdministration
  const { casaWindowState } = useCasaWindowContext();
  const { userState, updateUserState } = useUserStateContext();
  // Container width state and ref
  const appContainerRef = useRef(null);
  const { APIState } = useAPIContext();
  const [loading, setLoading] = useState(true);
  const [aviUrl, setAviUrl] = useState("");
  const [employeeData, setEmployeeData] = useState({});
  const [openScreen, setOpenScreen] = useState({
    information: false,
    permissions: false,
    reports: false,
    apps: false,
  });

  async function initEmployeeDash() {
    setLoading(true);
    await getAviUrl();
    await getUserInfo();
    setLoading(false);
  }

  async function getAviUrl() {
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetUserPhoto",
          EmplyID: administrationState.employee.EmplyID,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (response.data) {
        setAviUrl(response.data.url);
      }
      return response.data.url;
    } catch (e) {}
  }

  async function getUserInfo() {
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetUserInfo",
          EmplyID: administrationState.employee.EmplyID,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      if (response.data) {
        setEmployeeData(response.data);
      }
      return response.data;
    } catch (e) {}
  }

  useEffect(() => {
    initEmployeeDash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKey = () => {
    if (loading) return 0; // index 0 is for newChat
    if (openScreen.information) {
      return 2;
    } else if (openScreen.permissions) {
      return 3;
    } else if (openScreen.reports) {
      return 4;
    } else if (openScreen.apps) {
      return 5;
    }
    return 1; // index 1 is for Messages
  };

  const compArr = [
    <div
      ref={appContainerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <CasaLoadingComponent />
    </div>,
    <div
      ref={appContainerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        marginTop: "-15vh",
        overflow: "hidden",
      }}
    >
      <>
        <Avatar
          src={aviUrl}
          style={{ width: "6em", height: "6em", marginBottom: "8px" }}
        />
        <Typography
          variant="h5"
          color="white"
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "4px",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          {administrationState.employee.FullName}
        </Typography>

        <Button
          style={{ marginTop: "8px" }}
          variant="contained"
          color="primary"
          onClick={props.backButton}
        >
          Back
        </Button>
        <div style={{ display: "flex", marginTop: "3vh" }}>
          <CasaAppIcon
            title="Information"
            width={casaWindowState.width}
            color="#08005C"
            altColor="#0D0078"
            type="action"
            casaClickHandle={() => {
              setOpenScreen({ information: true });
            }}
          />
          <CasaAppIcon
            title="Permissions"
            width={casaWindowState.width}
            color="#B60080"
            altColor="#934747"
            type="action"
            casaClickHandle={() => {
              setOpenScreen({ permissions: true });
            }}
          />
          <CasaAppIcon
            title="Reports"
            width={casaWindowState.width}
            color="#C18700"
            altColor="#BFA836"
            type="action"
            casaClickHandle={() => {
              setOpenScreen({ reports: true });
            }}
          />
          <CasaAppIcon
            title="Apps"
            width={casaWindowState.width}
            color="#D84700"
            altColor="#FFA836"
            type="action"
            casaClickHandle={() => {
              setOpenScreen({ apps: true });
            }}
          />
        </div>
      </>
    </div>,
    <CasaConfigureComponent
      title="Information"
      closeModalFunction={() => {
        setOpenScreen({ information: false });
      }}
      employeeData={employeeData}
      employee={administrationState.employee}
      editEnabled={true}
    />,
    <CasaConfigureComponent
      title="Permissions"
      closeModalFunction={() => {
        setOpenScreen({ permissions: false });
      }}
      editEnabled={true}
    />,
    <CasaConfigureComponent
      title="Reports"
      closeModalFunction={() => {
        setOpenScreen({ reports: false });
      }}
      editEnabled={false}
    />,

    <CasaConfigureComponent
      title="Apps"
      closeModalFunction={() => {
        setOpenScreen({ apps: false });
      }}
      editEnabled={false}
    />,
  ];

  return (
    <SwitchTransition>
      <CSSTransition
        key={getKey()}
        timeout={150}
        classNames="fade-messages"
        unmountOnExit
      >
        <div
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {compArr[getKey()]}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default ViewEmployee;
