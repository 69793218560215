import { createContext, useContext, useState } from "react";

const AdministrationContext = createContext();

export const useAdministrationContext = () => {
  const context = useContext(AdministrationContext);
  if (!context) {
    throw new Error(
      "useAdministrationContext must be used within an AdministrationProvider"
    );
  }
  return context;
};

export const AdministrationProvider = ({ children }) => {
  const [administrationState, setAdministrationState] = useState({
    employee: {},
    employeeCasaID: "",
    customer: {},
    customerID: "",
    employeeList: [],
    customerList: [
      "Lennar",
      "Highland Homes",
      "D.R. Horton",
      "David Weekly",
      "Pulte",
      "Taylor Morrison",
      "Scott Felder",
    ],
  });

  const updateAdministration = (newState) => {
    setAdministrationState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <AdministrationContext.Provider
      value={{ administrationState, updateAdministration }}
    >
      {children}
    </AdministrationContext.Provider>
  );
};
