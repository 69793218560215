// CasaAppIcon.js
import React from "react";
import { Link } from "react-router-dom";

import {
  Chat as ChatIcon,
  PunchClock as PunchClockIcon,
  Contacts as ContactsIcon,
  ContactPage as ContactPageIcon,
  LockPerson as LockPersonIcon,
  AcUnit as AcUnitIcon,
  WaterDrop as WaterDropIcon,
  Settings as SettingsIcon,
  Info as InfoIcon,
  Apps as AppsIcon,
  FilterList,
  SupervisorAccount,
  Warehouse,
  HomeRepairService,
  LibraryAdd,
  CheckBox,
  Numbers,
  Search,
  Flip,
  Badge,
  HomeWork,
  RequestQuote,
  ShoppingCart,
  Edit,
  Description,
  LocalShipping,
  GridView,
  EventRepeat,
  Construction,
  KeyboardDoubleArrowUp,
  Engineering,
  History,
  Sell,
  Workspaces,
  Category,
  Splitscreen,
  Face,
  Dashboard,
} from "@mui/icons-material";

function getIcon(title, width) {
  // Define base icon size
  let baseSize = 30;

  // Adjust base size based on screen width
  if (width >= 1000) {
    baseSize = 60; // Large screens
  } else if (width >= 450) {
    baseSize = 45; // Medium screens
  }

  // Calculate icon size based on title and adjusted base size
  let iconSize = baseSize + 20;
  switch (title) {
    case "Messages":
      return <ChatIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Inventory":
      return <Warehouse sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Plumbing":
      return (
        <WaterDropIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Air Conditioning":
      return <AcUnitIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Time Clock":
      return (
        <PunchClockIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Information":
      return <ContactsIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Permissions":
      return (
        <LockPersonIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Reports":
      return (
        <ContactPageIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Administration":
      return (
        <SupervisorAccount sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Settings":
      return <SettingsIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Updates":
      return <InfoIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Apps":
      return <AppsIcon sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Filter":
      return <FilterList sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Search":
      return <Search sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Scan Code":
      return <Flip sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Assembly":
      return (
        <HomeRepairService sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "Counts":
      return <Numbers sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Review":
      return <CheckBox sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Create":
      return <LibraryAdd sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Employee":
      return <Badge sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Customers":
      return <HomeWork sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Orders":
      return <RequestQuote sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Purchasing":
      return <ShoppingCart sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Edit":
      return <Edit sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Notes":
      return <Description sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Item Status":
      return (
        <LocalShipping sx={{ color: "#fff", fontSize: `${iconSize}px` }} />
      );
    case "View":
      return <GridView sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Backorders":
      return <EventRepeat sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Parts":
      return <Construction sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Details":
      return <Construction sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Stages":
      return (
        <KeyboardDoubleArrowUp
          sx={{ color: "#fff", fontSize: `${iconSize}px` }}
        />
      );
    case "Admin":
      return <Engineering sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Tags":
      return <Sell sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Logs":
      return <History sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Type":
      return <Splitscreen sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Group":
      return <Workspaces sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Category":
      return <Category sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "CasaGPT":
      return <Face sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    case "Dashboard":
      return <Dashboard sx={{ color: "#fff", fontSize: `${iconSize}px` }} />;
    default:
      return null;
  }
}

const CasaAppIcon = (props) => {
  const commonStyles = {
    width: "17vw",
    height: "17vw",
    maxWidth: "120px",
    maxHeight: "120px",
    borderRadius: "12px",
    background: `linear-gradient(45deg, ${props.color}, ${props.altColor})`,
    margin: "0.5vw 2vw", // Vertical and horizontal margin
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Center vertically and horizontally
  };

  if (props.type === "action") {
    return (
      <div
        onClick={props.type === "action" ? props.casaClickHandle : undefined}
        style={{
          textDecoration: "none",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={commonStyles}>{getIcon(props.title, props.width)}</div>
        <span style={{ color: "#fff", fontSize: "clamp(10px, 3vw, 25px)" }}>
          {props.title}
        </span>
      </div>
    );
  }

  return (
    <Link
      to={props.linkto} // handle both linked app icons and action icons
      style={{
        textDecoration: "none",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={commonStyles}>{getIcon(props.title, props.width)}</div>
      <span style={{ color: "#fff", fontSize: "clamp(10px, 3vw, 25px)" }}>
        {props.title}
      </span>
    </Link>
  );
};

export default CasaAppIcon;
