// CasaGPT.js
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import { useUserStateContext } from "../../../contexts/UserStateContext";

const CasaGPT = (props) => {
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const { casaWindowState } = useCasaWindowContext();

  const containerRef = useRef(null);
  const bottomRef = useRef(null);
  const theme = useTheme();

  const [scrollDownCount, setScrollDownCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // const [conversations, setConversations] = useState([]);
  const [conversationData, setConversationData] = useState([
    {
      role: "assistant",
      content:
        "Hello! I am CasaGPT. Ask me any question, or ask me to perform a query.",
    },
  ]);
  const [selectedConversation, setSelectedConversation] = useState({});

  const [messageText, setMessageText] = useState("");
  const inputRef = useRef(null); // Create a ref to the input field

  const [dataViewOpen, setDataViewOpen] = useState(false);
  const [messageData, setMessageData] = useState([{}]);

  useEffect(() => {
    inputRef?.current?.focus(); // Automatically focus the input when the component mounts
  }, [loading]);

  // API calls
  async function getConversation(conversation) {
    setLoading(true);
    setConversationData([]);
    // setSelectedConversation(conversation);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    try {
      const response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "GetMsgs",
          MsgGroup: conversation.GroupID,
          MsgTo: conversation.MsgTo,
          MsgDate: "",
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      setConversationData(response.data);
      setScrollDownCount(scrollDownCount + 1);
      setLoading(false);
      return response.data;
    } catch (e) {}
  }

  function removeQueriesFromHistory(arr) {
    let newArr = arr.map(({ MessageData, ...rest }) => rest);
    return newArr;
  }

  async function sendMessageToAskCasa() {
    setLoading(true);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    setConversationData([
      ...conversationData,
      {
        content: "Awaiting CasaGPT",
        role: "assistant",
      },
    ]);
    let response;
    try {
      response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "AskCasa",
          AskQuestion: messageText, //"What are the Addresses of the plumbing work orders created last week"
          EmplyID: userState.emplyId,
          GPTSecurityFlags: userState.gptSecurityFlags,
          AskConversation: JSON.stringify({
            messages: removeQueriesFromHistory(conversationData),
          }),
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      if (response?.data?.casaResponse) {
        const msgObj = {
          content: response.data.casaResponse,
          role: "assistant",
        };
        setConversationData([...conversationData, msgObj]);
      }
      setLoading(false);
      return response?.data;
    } catch (e) {
      setLoading(false);
      console.log(e.response?.data?.error);
      setConversationData([
        ...conversationData,
        {
          content: e.response?.data?.error,
          role: "assistant",
        },
      ]);
    }
  }

  async function sendMessageToQueryCasa() {
    setLoading(true);
    const userAuthObj = await GetUserAndTokens(userState, updateUserState);
    setConversationData([
      ...conversationData,
      {
        content: "Awaiting CasaGPT",
        role: "assistant",
      },
    ]);
    let response;
    try {
      response = await axios.get(APIState.SysAPI, {
        params: {
          SysFunc: "QueryCasa",
          AskQuestion: messageText, //"What are the Addresses of the plumbing work orders created last week"
          EmplyID: userState.emplyId,
          GPTSecurityFlags: userState.gptSecurityFlags,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log(response);
      if (response?.data?.recordset) {
        const msgObj = {
          content:
            response.data.recordset.length === 0
              ? "My apologies. I searched the database, but couldn't find any records matching your query."
              : "I managed to find " +
                response?.data?.recordset.length +
                " matching records. Click here to view them.",
          role: "assistant",
          MessageData: response.data.recordset,
        };
        setConversationData([...conversationData, msgObj]);
      }
      setLoading(false);
      return response?.data;
    } catch (e) {
      setLoading(false);
      console.log(e.response?.data?.error);
      setConversationData([
        ...conversationData,
        {
          content: !e.response?.data?.error
            ? "Sorry, I couldn't get a response (request timed out)."
            : e.response?.data?.error,
          role: "assistant",
        },
      ]);
    }
  }

  // useEffects
  useEffect(() => {
    if (conversationData.length > 0) {
      scrollToBottomMsg();
    }
  }, [conversationData, scrollDownCount]);

  function scrollToBottomMsg() {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  const handleConversationSelect = (conversation) => {
    setMessageText("");
    getConversation(conversation);
  };

  function handleBack() {
    // setSelectedConversation(null);
    setMessageText("");
    // setScrollDownCount(scrollDownCount + 1);
  }

  function sendMessage() {
    const updatedConversationData = conversationData;
    const newMsg = {
      MessageGroupX: selectedConversation.GroupID,
      MessageTo: selectedConversation.GroupName,
      MessageToID: selectedConversation.MsgTo,
      role: userState.emplyId,
      content: messageText,
    };
    updatedConversationData.push(newMsg);
    if (messageText.toLowerCase().includes("query")) {
      sendMessageToQueryCasa();
    } else {
      sendMessageToAskCasa();
    }
    setMessageText("");
    setScrollDownCount(scrollDownCount + 1);
  }

  const formatText = (text) => {
    const isMultiLine = text.includes("\n");
    return text.split("\n").map((line, index) => (
      <Typography key={index} paragraph={isMultiLine}>
        {line}
      </Typography>
    ));
  };

  const ResponseRenderer = ({ text }) => {
    return (
      <Box sx={{ padding: 0.5 }}>
        {text.split("\n\n").map((section, index) => {
          const lines = section.split("\n");

          // Check if the section contains numbered items, like "1.", "2.", etc.
          const isNumbered = lines[0]
            .substring(0, lines[0].indexOf("."))
            .match(/^\d/); // Check if the first character is a number followed by a period

          if (isNumbered) {
            return (
              <Box key={index}>
                {/* Render the first line as h6 (for numbered lists) */}
                <Typography variant="h6">{lines[0]}</Typography>
                <List>
                  {/* Render the rest of the list items */}
                  {lines.slice(1).map((item, idx) => {
                    // Render numbered list items without numbers
                    if (item.substring(0, item.indexOf(".")).match(/^\d/)) {
                      return <Typography variant="h6">{item}</Typography>;
                    } else {
                      // console.log(item, item.substring(0, item.indexOf(".")));
                    }

                    return (
                      <ListItem key={idx}>
                        <ListItemText
                          primary={item.replace(/^\d+\./, "").trim()} // Remove numbers from list items
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider />
              </Box>
            );
          } else {
            // If it's not a numbered list, treat it as regular text or a bulleted list
            return <Box key={index}>{formatText(section)}</Box>;
          }
        })}
      </Box>
    );
  };

  function CasaGPTChat() {
    return (
      <div
        key="messages"
        ref={containerRef}
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Paper style={{ position: "relative", width: "100%" }}>
          {/* <CasaCloseButton handleClose={props.backButton} /> */}
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              borderBottom: `1px solid ${theme.palette.divider}`,
              boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {/* Close Icon */}
            <CasaCloseButton handleClose={props.backButton} />

            {/* Title */}
            <Typography
              variant="h4"
              sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
            >
              <b>CasaGPT</b>
            </Typography>
          </Box>
          {/* Message Pane */}
          {(casaWindowState.width < 900 || //&& selectedConversation
            casaWindowState.width < 900 ||
            casaWindowState.width >= 900) && (
            <Grid item xs={12} style={{ position: "relative" }}>
              <Box
                pl={2}
                style={{
                  // overflow: selectedConversation ? "auto" : "hidden",
                  overflow: "auto",
                  height:
                    casaWindowState.width < 900 ? "calc(65vh)" : "calc(55vh)",
                  padding: "1.25vw",
                }}
              >
                <div>
                  {
                    // selectedConversation &&
                    Array.isArray(conversationData) &&
                      conversationData.map((message, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign:
                              message.role !== "assistant" ? "right" : "left",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              backgroundColor:
                                message.role !== "assistant"
                                  ? "#007bff"
                                  : "#e8e8e8",
                              color:
                                message.role !== "assistant" ? "#fff" : "#000",
                              borderRadius: "10px",
                              padding: "10px",
                              maxWidth: "70%",
                              wordWrap: "break-word",
                              textAlign:
                                message.role !== "assistant"
                                  ? "left"
                                  : "inherit",
                            }}
                          >
                            {message.MessageData?.length > 0 ? (
                              <button
                                onClick={() => {
                                  setDataViewOpen(true);
                                  setMessageData(message.MessageData);
                                  console.log(
                                    "message data: ",
                                    message.MessageData
                                  );
                                }}
                              >
                                View Data ({message.MessageData.length})
                              </button>
                            ) : message.content === "Awaiting CasaGPT" ? (
                              <CircularProgress />
                            ) : (
                              <ResponseRenderer text={message.content} />
                            )}
                          </div>
                        </div>
                      ))
                  }
                  <div ref={bottomRef} />
                </div>
              </Box>
              {/* Message Text Field */}

              <>
                <Box
                  position="sticky"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "10px 10px",
                    //border: "2px solid", // Add border
                    // border:
                    //   theme.components.MuiPaper.styleOverrides.root.border,
                    // borderRadius: "10px", // Add border radius
                  }}
                >
                  <InputBase
                    id="new_message_text"
                    inputRef={inputRef}
                    value={messageText}
                    onChange={(e) => {
                      setMessageText(e.target.value);
                    }}
                    sx={{
                      ml: 1,
                      flex: 1,
                    }}
                    placeholder={
                      loading ? "Awaiting response..." : "Message CasaGPT"
                    }
                    inputProps={{ "aria-label": "Send Message" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendMessage();
                      }
                    }}
                    onFocus={(e) => {
                      setScrollDownCount(scrollDownCount + 1);
                    }}
                    fullWidth
                    disabled={loading} //!selectedConversation
                  />
                  <Divider sx={{ height: 38, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="send"
                    disabled={!messageText}
                    onClick={sendMessage}
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              </>
            </Grid>
          )}
        </Paper>
      </div>
    );
  }

  function dataViewModal() {
    return (
      <div>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            position: "relative",
            borderRadius: "8px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <CasaCloseButton
            handleClose={() => {
              setDataViewOpen(false);
              setPage(0);
              setRowsPerPage(25);
            }}
          />
          <h1>Query Result</h1>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "100%", minHeight: "60vh", maxHeight: "60vh" }}
          >
            <Table
              stickyHeader
              sx={{
                minWidth: "100%",
                borderCollapse: "collapse",
              }}
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {Object.keys(messageData[0]).map((col) => (
                    <TableCell key={col} style={{ fontWeight: "bold" }}>
                      {col.charAt(0).toUpperCase() + col.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {messageData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.keys(messageData[0]).map((col) => (
                        <TableCell key={`${rowIndex}-${col}`}>
                          {row[col]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500]}
            component="div"
            count={messageData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
        </Paper>
      </div>
    );
  }

  const getKey = () => {
    if (dataViewOpen) return 1; // index 0 is for newChat
    return 0; // index 1 is for Messages
  };

  const compArr = [CasaGPTChat(), dataViewModal()];

  return (
    <SwitchTransition>
      <CSSTransition
        key={getKey()}
        timeout={150}
        classNames="fade-messages"
        unmountOnExit
      >
        <div
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {compArr[getKey()]}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default CasaGPT;
