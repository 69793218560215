import React from "react";
import Box from "@mui/material/Box";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Updates = (props) => {
  const theme = useTheme();

  const notes = [
    "Progress on multi-organizational support (switching betweeen Casa Round Rock + San Antonio).",
    "Improved styling of Settings, Updates, and others.",
    "Improved styling of Assembly Stage information within Inventory Search.",
    "Optimized user API calls to use cached tokens.",
    "Finalizing Inventory views/designs.",
    "Progress on Purchase Orders + Backorders API integration.",
    "Progress on Metric Dashboards.",
    "Progress on Employee Administration.",
    "Progress on creating Inventory Admin + adding Inventory Tag support.",
    "Miscellaneous improvements to CasaGPT.",
  ];

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          maxWidth: "800px",
          mx: "auto",
          maxHeight: "60vh",
          position: "relative",
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor,
            py: 2,
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography variant="h3">💾</Typography>
          <Typography variant="h5" color="white" fontWeight={600}>
            Casa Application Updates
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Current Build: <b>February 14th, 2025</b>
          </Typography>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <div sx={{ overflow: "auto" }}>
          <Box sx={{ maxHeight: "40vh", overflowY: "auto", px: 2 }}>
            <List sx={{ textAlign: "left" }}>
              {notes.map((note, index) => (
                <ListItem
                  key={index}
                  sx={{
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{ color: theme.palette.primary.main, mr: 1 }}
                  />
                  <ListItemText
                    primary={note}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      </Paper>
    </div>
  );
};

export default Updates;
