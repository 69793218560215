import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SvgIcon from "@mui/material/SvgIcon";

import PaymentsIcon from "@mui/icons-material/Payments";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DescriptionIcon from "@mui/icons-material/Description";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { Dashboard, QuestionMark } from "@mui/icons-material";

function CasaAppBar(props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // contexts
  const { userState, updateUserState } = useUserStateContext();
  const { updateInventory } = useInventoryContext();

  const { signOut } = useAuthenticator((context) => [context.signOut]);

  //reset inventory filter screen
  const handleBackPressContextReset = () => {
    updateInventory({ activeStep: 0, searchType: "" });
    navigate(getParentURL());
    // if (inventoryState.activeStep > 2) {
    //   updateInventory({ activeStep: inventoryState.activeStep - 1 });
    // } else {
    //   updateInventory({ activeStep: 0 });
    //   navigate(getParentURL());
    //   // navigate(-1);
    // }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOutUser = async () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  const getParentURL = () => {
    // Trim any trailing slashes
    const newUrl = url.replace(/\/+$/, "");

    // Split the URL by slashes
    const parts = newUrl.split("/");

    // If the URL is root or has no parts, return root
    if (parts.length <= 1) {
      return "/";
    }

    // Remove the last part to get the parent directory
    parts.pop();

    // Join the remaining parts back together
    const parentURL = parts.join("/");

    // Ensure the result starts with a slash and return
    const ret = parentURL.startsWith("/") ? parentURL : "/" + parentURL;
    return ret;
  };

  function getPathFromUrl(url) {
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
  }

  function getWikiDocsLink() {
    const str = getPathFromUrl(window.location);
    let url = "https://master.d3jvk20l3o8j3x.amplifyapp.com";
    // handle root
    if (str === "/") {
      return "https://master.d3jvk20l3o8j3x.amplifyapp.com/docs/category/overview";
    }
    // handle category folders
    if (str === "/inventory") {
      return url + "/docs/category" + str;
    }
    // handle individual screens
    return url + "/docs" + str;
  }

  const above_line_menu = [
    { label: "Home", icon: <HomeIcon />, destination: "/" },
    { label: "Help", icon: <QuestionMark />, destination: "/wiki" },
    { label: "Dashboard", icon: <Dashboard />, destination: "/dashboard" },
  ];

  const below_line_menu = [
    { label: "Estimating", icon: <PaymentsIcon />, destination: "/estimating" },
    {
      label: "Scheduling",
      icon: <CalendarMonthIcon />,
      destination: "/scheduling",
    },
    { label: "Inventory", icon: <WarehouseIcon />, destination: "/inventory" },
    {
      label: "Purchasing",
      icon: <ShoppingCartIcon />,
      destination: "/purchasing",
    },
    {
      label: "Job Records",
      icon: <RequestQuoteIcon />,
      destination: "/jobrecords",
    },
    {
      label: "Administration",
      icon: <SupervisorAccountIcon />,
      destination: "/administration",
    },
    { label: "Reports", icon: <DescriptionIcon />, destination: "/reports" },
    {
      label: "Time Clock",
      icon: <PunchClockIcon />,
      destination: "/timeclock",
    },
    { label: "Managers", icon: <QueryStatsIcon />, destination: "/managers" },
    { label: "Fleet", icon: <DriveEtaIcon />, destination: "/fleet" },
  ];

  const settings_menu = [
    {
      label: "Settings",
      icon: <SettingsIcon sx={{ mr: 1 }} />,
      destination: "/settings",
    },
  ];

  const logout_menu = [
    {
      label: "Log Out",
      icon: <ExitToAppIcon sx={{ mr: 1 }} />,
      destination: "/logout",
    },
  ];

  const avatarImagePath = require("../../../images/default_avi.png");

  const map_list_options = (option_arr, click_function) =>
    option_arr.map(({ label, icon, destination }) => (
      <List key={label}>
        <ListItem key={label} disablePadding>
          {destination === "/logout" ? (
            <ListItemButton onClick={signOut}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          ) : destination === "/wiki" ? (
            <ListItemButton
              component="a"
              href={getWikiDocsLink()}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          ) : (
            <ListItemButton
              component={Link}
              to={destination}
              onClick={click_function}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          )}
        </ListItem>
      </List>
    ));

  let url = useLocation().pathname;
  const navigate = useNavigate();

  // This function is intented to handle behavior when switching screens -
  // e.g. if a back click from one screen to its previous needs to have unique behavior.
  const handleBackClick = () => {
    if (
      url === "/inventory/ac/filter" ||
      url === "/inventory/plumbing/filter"
    ) {
      handleBackPressContextReset();
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        left: 0,
        right: 0,
        top: 0,
        m: 0,
        backgroundColor: theme.palette.customColors.CasaAppBarColor,
      }}
    >
      <Container maxWidth="false">
        <Toolbar disableGutters>
          {/* Hamburger Menu Icon (fixed to the left) */}
          <IconButton
            size="large"
            aria-label="Open"
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{
              ml: "-8px", // Adjust the marginLeft as needed
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {/* {!userState.hideDepartmentSelector && (
                <> */}
              <Box sx={{ minWidth: 120, padding: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="department-select-label">
                    Department
                  </InputLabel>
                  <Select
                    disabled={userState.departmentLock}
                    labelId="department-select-label"
                    id="department-select"
                    value={props.department}
                    label="Department"
                    onChange={(e) => {
                      props.setDepartment(e.target.value);
                    }}
                  >
                    <MenuItem value={"Air Conditioning"}>
                      Air Conditioning
                    </MenuItem>
                    <MenuItem value={"Plumbing"}>Plumbing</MenuItem>
                    <MenuItem value={"Service"}>Service</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Divider />
              {/* </>
              )} */}
              {map_list_options(above_line_menu, null)}

              <Divider />
              {map_list_options(below_line_menu, null)}
            </Box>
          </Drawer>
          {/* Center Icon (fixed to the center) */}
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <Link
              onClick={handleBackClick}
              to={getParentURL()}
              style={{ color: "white", textDecoration: "none" }}
            >
              <SvgIcon
                sx={{
                  paddingTop: "4px",
                  fontSize: "3.5rem", // Adjust the icon size if needed
                  verticalAlign: "middle", // Center vertically
                }}
              >
                {userState.appBarLogo}
              </SvgIcon>
              {/* <br /> */}
              <span
                style={{
                  display: "block",
                  fontSize: "1rem", // Adjust the icon size if needed
                  verticalAlign: "middle", // Center vertically
                }}
              >
                {props.department}
              </span>
            </Link>
          </Box>

          {/* User Avatar Icon (fixed to the right) */}
          <Tooltip title={userState.name}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src={userState.PicURL} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {map_list_options(settings_menu, handleCloseUserMenu)}
            {map_list_options(logout_menu, handleLogOutUser)}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default CasaAppBar;
