import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

const isTokenExpired = (idToken) => {
  if (!idToken || !idToken.payload || !idToken.payload.exp) {
    return true; // Treat missing token as expired
  }
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  return idToken.payload.exp < currentTime;
};

export async function GetUserAndTokens(userState, updateUserState) {
  if (
    userState.idToken &&
    userState.accessToken &&
    !isTokenExpired(userState.idToken)
  ) {
    console.log("using cached tokens...");
    return {
      user: userState.user,
      accessToken: userState.accessToken,
      idToken: userState.idToken,
    };
  }

  try {
    const userData = await getCurrentUser();
    if (!userData) {
      console.error("Failed to retrieve Cognito user.");
      throw new Error("CasaException: Failed to retrieve Cognito user.");
    }
    const session = await fetchAuthSession();
    const accessTokenData = session.tokens.accessToken;
    const idTokenData = session.tokens.idToken;

    if (!accessTokenData || !idTokenData) {
      console.error("Failed to retrieve user tokens.");
      throw new Error("CasaException: Failed to retrieve user tokens.");
    }

    updateUserState({
      user: userData,
      accessToken: accessTokenData,
      idToken: idTokenData,
    });

    console.log("fetched new tokens");
    return {
      user: userData,
      accessToken: accessTokenData,
      idToken: idTokenData,
    };
  } catch (e) {
    console.error("Timeclock API error.");
    return [];
  }
}
