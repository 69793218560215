// CasaLoadingComponent.js
import React from "react";
import { CircularProgress, Paper } from "@mui/material";

const CasaLoadingComponent = (props) => {
  return (
    <Paper
      sx={{
        height: "100px",
        width: "100px",
        mb: 2,
        padding: "1rem",
        position: "relative",
        borderRadius: "8px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Paper>
  );
};

export default CasaLoadingComponent;
