import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CasaAppIcon from "../CasaAppIcon/CasaAppIcon";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useEffect } from "react";

const CasaDetailDashComponent = (props) => {
  const { casaWindowState } = useCasaWindowContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const theme = useTheme();

  useEffect(() => {
    if (props.departmentLock) {
      props.departmentLock();
    }
  }, []);

  const getGridTemplate = () => {
    if (casaWindowState.width > 900) {
      if (
        (props.dashtype === "purchase-order" || props.dashtype === "purchase-backorder") &&
        props.editRow.PoStatusName === "Pending"
      ) {
        return { columns: "repeat(4, 1fr)", rows: "repeat(1, 1fr)" }; // 6x2 for wider screens
      } else {
        return { columns: "repeat(3, 1fr)", rows: "repeat(1, 1fr)" }; // 6x2 for wider screens
      }
    } else {
      return { columns: "repeat(2, 1fr)", rows: "repeat(2, 1fr)" }; // 4x3 for medium screens
    }
  };

  const gridTemplate = getGridTemplate();

  function getDashType() {
    if (props.dashtype === "inventory-review") {
      console.log("editRow: ", props.editRow);
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            ml: "20%",
            mr: "20%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              padding: 3,
              gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
            }}
          >
            {/* Vendor Picture */}
            <Avatar
              src={props.editRow.InvImage}
              alt={props.editRow.InvDescription}
              sx={{
                width: 150,
                height: 150,
                boxShadow: 2,
                marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            />

            {/* Vendor Details */}
            <Stack
              spacing={1}
              sx={{
                alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
              }}
            >
              <Typography variant="h4">
                {props.editRow.InvDescription}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <u>
                  {
                    inventoryState.filterArrays[1]?.relatedRows.find(
                      (item) =>
                        item.ExtID ===
                        "2" +
                          props.editRow.InvDept +
                          "-" +
                          props.editRow.InvType
                    )?.LevelDesc
                  }
                </u>
                {" / "}
                <u>
                  {
                    inventoryState.filterArrays[2]?.relatedRows.find(
                      (item) =>
                        item.ExtID ===
                        "3" +
                          props.editRow.InvType +
                          "-" +
                          props.editRow.InvCategory
                    )?.LevelDesc
                  }
                </u>
                {" / "}
                <u>
                  {
                    inventoryState.filterArrays[3]?.relatedRows.find(
                      (item) => item.ExtID === "4-" + props.editRow.InvGroup
                    )?.LevelDesc
                  }
                </u>
                <br />
                {props.editRow.InvNotes
                  ? "Notes: " + props.editRow.InvNotes
                  : props.editRow.InvCost > 0
                  ? "$" +
                    props.editRow.InvCost +
                    " " +
                    (props.editRow.InvUnit ? props.editRow.InvUnit : "")
                  : null}
              </Typography>
            </Stack>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              gap: { xs: 2, md: "2vw" }, // Adjust gap for smaller screens
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => console.log("Order Placed")}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Edit Details
            </Button>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => console.log("Order Placed")}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Delete Assembly
            </Button>
          </Box> */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: gridTemplate.columns,
              gridTemplateRows: gridTemplate.rows,
              gap: "1.5vh",
              marginTop: "2vh",
              padding: "1rem",
              background: "rgba(100, 100, 100, 0.1)", // Translucent white background
              borderRadius: "15px", // Rounded corners
            }}
          >
            <CasaAppIcon
              title="Details"
              type="action"
              casaClickHandle={props.openDetailsModal}
              width={casaWindowState.width}
              color="green"
              altColor="olive"
            />
            <CasaAppIcon
              title="Reports"
              type="action"
              casaClickHandle={(e) => {
                console.log("reports opened");
              }}
              width={casaWindowState.width}
              color="maroon"
              altColor="red"
            />
            <CasaAppIcon
              title="Stages"
              type="action"
              casaClickHandle={props.openCounts}
              width={casaWindowState.width}
              color="#08005C"
              altColor="#0D0078"
            />
          </div>
        </Box>
      );
    } else if (props.dashtype === "inventory-search") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            ml: "20%",
            mr: "20%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              padding: 3,
              gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
            }}
          >
            {/* Vendor Picture */}
            <Avatar
              src={props.editRow.InvImage}
              alt={props.editRow.InvDescription}
              sx={{
                width: 150,
                height: 150,
                boxShadow: 2,
                marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            />

            {/* Vendor Details */}
            <Stack
              spacing={1}
              sx={{
                alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
              }}
            >
              <Typography variant="h4">
                {props.editRow.InvDescription}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {props.editRow.InvNotes}
              </Typography>
            </Stack>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              gap: { xs: 2, md: "2vw" }, // Adjust gap for smaller screens
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => console.log("Order Placed")}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Edit Details
            </Button>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => console.log("Order Placed")}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Delete Assembly
            </Button>
          </Box> */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: gridTemplate.columns,
              gridTemplateRows: gridTemplate.rows,
              gap: "1.5vh",
              marginTop: "2vh",
              padding: "1rem",
              background: "rgba(100, 100, 100, 0.1)", // Translucent white background
              borderRadius: "15px", // Rounded corners
            }}
          >
            <CasaAppIcon
              title="View"
              type="action"
              casaClickHandle={props.openDetails}
              width={casaWindowState.width}
              color="green"
              altColor="olive"
            />
            <CasaAppIcon
              title="Reports"
              width={casaWindowState.width}
              color="maroon"
              altColor="red"
              // linkto={`/administration/search-employees/view/information`}
            />
            <CasaAppIcon
              title="Counts"
              width={casaWindowState.width}
              color="#08005C"
              altColor="#0D0078"
              // linkto={`/administration/search-employees/view/information`}
            />
          </div>
        </Box>
      );
    } else if (props.dashtype === "inventory-assemblies") {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            ml: "20%",
            mr: "20%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              padding: 3,
              gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
            }}
          >
            {/* Vendor Picture */}
            <Avatar
              src={props.inventoryState?.assembly?.InvImage}
              alt={props.inventoryState?.assembly?.InvDescription}
              sx={{
                width: 150,
                height: 150,
                boxShadow: 2,
                marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            />

            {/* Vendor Details */}
            <Stack
              spacing={1}
              sx={{
                alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
              }}
            >
              <Typography variant="h4">
                {props.editRow.InvDescription}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <u>
                  {
                    inventoryState.filterArrays[1]?.relatedRows.find(
                      (item) =>
                        item.ExtID ===
                        "2" +
                          props.editRow.InvDept +
                          "-" +
                          props.editRow.InvType
                    )?.LevelDesc
                  }
                </u>
                {" / "}
                <u>
                  {
                    inventoryState.filterArrays[2]?.relatedRows.find(
                      (item) =>
                        item.ExtID ===
                        "3" +
                          props.editRow.InvType +
                          "-" +
                          props.editRow.InvCategory
                    )?.LevelDesc
                  }
                </u>
                {" / "}
                <u>
                  {
                    inventoryState.filterArrays[3]?.relatedRows.find(
                      (item) => item.ExtID === "4-" + props.editRow.InvGroup
                    )?.LevelDesc
                  }
                </u>
                <br />
                {props.editRow.InvNotes
                  ? "Notes: " + props.editRow.InvNotes
                  : props.editRow.InvCost > 0
                  ? "$" +
                    props.editRow.InvCost +
                    " " +
                    (props.editRow.InvUnit ? props.editRow.InvUnit : "")
                  : null}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
              gap: { xs: 2, md: "2vw" }, // Adjust gap for smaller screens
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={props.openDetailsModal}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Edit Details
            </Button>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => console.log("Delete Assembly")}
              sx={{
                whiteSpace: "nowrap",
                marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
              }}
            >
              Delete Assembly
            </Button>
          </Box>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: gridTemplate.columns,
              gridTemplateRows: gridTemplate.rows,
              gap: "1.5vh",
              marginTop: "2vh",
              padding: "1rem",
              background: "rgba(100, 100, 100, 0.1)", // Translucent white background
              borderRadius: "15px", // Rounded corners
            }}
          >
            <CasaAppIcon
              title="Parts"
              width={casaWindowState.width}
              color="green"
              altColor="olive"
              type="action"
              casaClickHandle={props.openPartsListModal}
            />
            <CasaAppIcon
              title="Reports"
              width={casaWindowState.width}
              color="maroon"
              altColor="red"
              type="action"
              casaClickHandle={() => {
                console.log("Reports clicked");
              }}
            />
            <CasaAppIcon
              title="Stages"
              type="action"
              casaClickHandle={props.openCounts}
              width={casaWindowState.width}
              color="#08005C"
              altColor="#0D0078"
            />
          </div>
        </Box>
      );
    } else if (props.dashtype === "purchase-order") {
      if (props.editRow.PoStatusName === "Draft") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "5vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {props.editRow.PoDate}
                </Typography>
                <Chip
                  label={props.editRow.PoStatusName}
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              {/* Place Order Button */}
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Order Placed")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Place Order
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={() => console.log("Order Placed")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Delete Draft
                </Button>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1.5vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="Edit"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("Edit");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
            </div>
          </Box>
        );
      } else if (props.editRow.PoStatusName === "Pending") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                {new Date(new Date(props.editRow.PoDate).setDate(new Date(props.editRow.PoDate).getDate() + 1)).toLocaleDateString()}

                  {props.editRow.Address ? " @ " + props.editRow.Address : null}
                </Typography>
                <Chip
                  label="PENDING"
                  color="warning"
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              <Stack spacing={1}>
                {/* First row with "Duplicate Order" button */}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Duplicate Order")}
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Duplicate Order
                </Button>

                {/* Second row with "Trash Icon" and "Backorder" buttons */}
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => console.log("Backorder")}
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: (theme) => theme.palette.warning.dark, // Set to warning.dark
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.warning.main, // Optional: Define hover color
                      },
                    }}
                  >
                    Backorder
                  </Button>
                  <IconButton
                    color="error"
                    onClick={() => console.log("Delete Order")}
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      backgroundColor: "#d32f2f",
                      "&:hover": {
                        backgroundColor: "#f44336",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="Edit"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("Edit");
                }}
              />
              <CasaAppIcon
                title="Item Status"
                width={casaWindowState.width}
                color="black"
                altColor="brown"
                type="action"
                casaClickHandle={() => {
                  console.log("Item Status");
                }}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
              />
            </div>
          </Box>
        );
      } else if (props.editRow.PoStatusName === "Completed") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                {new Date(new Date(props.editRow.PoDate).setDate(new Date(props.editRow.PoDate).getDate() + 1)).toLocaleDateString()}

                </Typography>
                <Chip
                  label="COMPLETED"
                  color="success"
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              {/* Place Order Button */}
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Duplicate")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Duplicate Order
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={() => console.log("Delete")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Delete Order
                </Button>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1.5vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="View"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("View");
                }}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
              />
            </div>
          </Box>
        );
      }
    } else if (props.dashtype === "purchase-backorder") {
      if (props.editRow.PoStatusName === "Draft") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "5vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {props.editRow.PoDate}
                </Typography>
                <Chip
                  label={props.editRow.PoStatusName}
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              {/* Place Order Button */}
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Order Placed")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Place Order
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={() => console.log("Order Placed")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Delete Draft
                </Button>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1.5vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="Edit"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("Edit");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
                // linkto={`/administration/search-employees/view/information`}
              />
            </div>
          </Box>
        );
      } else if (props.editRow.PoStatusName === "Pending") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                {new Date(new Date(props.editRow.PoDate).setDate(new Date(props.editRow.PoDate).getDate() + 1)).toLocaleDateString()}

                  {props.editRow.Address ? " @ " + props.editRow.Address : null}
                </Typography>
                <Chip
                  label="PENDING"
                  color="warning"
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              <Stack spacing={1}>
                {/* First row with "Duplicate Order" button */}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Duplicate Order")}
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Duplicate Order
                </Button>

                {/* Second row with "Trash Icon" and "Backorder" buttons */}
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => console.log("Clear Backorder")}
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: (theme) => theme.palette.info.dark, // Set to warning.dark
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.info.main, // Optional: Define hover color
                      },
                    }}
                  >
                    Clear Backorder
                  </Button>
                  <IconButton
                    color="error"
                    onClick={() => console.log("Delete Order")}
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      backgroundColor: "#d32f2f",
                      "&:hover": {
                        backgroundColor: "#f44336",
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="Edit"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("Edit");
                }}
              />
              <CasaAppIcon
                title="Item Status"
                width={casaWindowState.width}
                color="black"
                altColor="brown"
                type="action"
                casaClickHandle={() => {
                  console.log("Item Status");
                }}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
              />
            </div>
          </Box>
        );
      } else if (props.editRow.PoStatusName === "Completed") {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
                padding: 3,
                gap: { xs: 2, md: "3vw" }, // Adjust gap for smaller screens
              }}
            >
              {/* Vendor Picture */}
              <Avatar
                src={props.editRow.VendorImage}
                alt={props.editRow.VendorName}
                sx={{
                  width: 150,
                  height: 150,
                  boxShadow: 2,
                  marginBottom: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                }}
              />

              {/* Vendor Details */}
              <Stack
                spacing={1}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" }, // Center details on small screens
                  textAlign: { xs: "center", md: "left" }, // Adjust text alignment for small screens
                }}
              >
                <Typography variant="h4">{props.editRow.VendorName}</Typography>
                <Typography variant="body1" color="textSecondary">
                {new Date(new Date(props.editRow.PoDate).setDate(new Date(props.editRow.PoDate).getDate() + 1)).toLocaleDateString()}

                </Typography>
                <Chip
                  label="COMPLETED"
                  color="success"
                  sx={{
                    alignSelf: { xs: "center", md: "flex-start" }, // Center the chip on small screens
                    fontSize: "1rem",
                    fontWeight: "bold",
                    padding: "0 10px",
                  }}
                />
              </Stack>

              {/* Place Order Button */}
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => console.log("Duplicate")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Duplicate Order
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  onClick={() => console.log("Delete")}
                  sx={{
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, md: 0 }, // Add margin for spacing in column layout
                  }}
                >
                  Delete Order
                </Button>
              </Stack>
            </Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplate.columns,
                gridTemplateRows: gridTemplate.rows,
                gap: "1.5vh",
                marginTop: "2vh",
                padding: "1rem",
                background: "rgba(100, 100, 100, 0.1)", // Translucent white background
                borderRadius: "15px", // Rounded corners
              }}
            >
              <CasaAppIcon
                title="View"
                width={casaWindowState.width}
                color="green"
                altColor="olive"
                type="action"
                casaClickHandle={() => {
                  console.log("View");
                }}
              />
              <CasaAppIcon
                title="Reports"
                width={casaWindowState.width}
                color="maroon"
                altColor="red"
                type="action"
                casaClickHandle={() => {
                  console.log("Reports");
                }}
              />
              <CasaAppIcon
                title="Notes"
                width={casaWindowState.width}
                color="#08005C"
                altColor="#0D0078"
                type="action"
                casaClickHandle={() => {
                  console.log("Notes");
                }}
              />
            </div>
          </Box>
        );
      }
    }
  }

  return (
    <div>
      <Paper
        sx={{
          width: "100%", // Full width of its container
          maxWidth: "1500px", // Maximum width if needed
          margin: "auto", // Center the Paper component
          display: "flex",
          flexDirection: "column",
          mb: "2vh",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Close Icon */}
          <IconButton
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={props.closeModalFunction}
          >
            <CloseIcon />
          </IconButton>
          {/* Title */}
          <Typography
            variant="h4"
            sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
          >
            <b>{props.title}</b>
          </Typography>
        </Box>

        {/* Scrollable Form Section */}
        <Box
          sx={{
            flex: 1, // Takes up the remaining space
            overflowY: "auto", // Enables vertical scrolling
            paddingTop: "5px",
            paddingBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {getDashType()}
        </Box>
      </Paper>
    </div>
  );
};

export default CasaDetailDashComponent;
