// CasaConfigureComponent.js
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CasaSwitchForm from "../CasaSwitchForm/CasaSwitchForm";
import { useState } from "react";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import CasaAppIcon from "../CasaAppIcon/CasaAppIcon";
import CasaDetailDashComponent from "../CasaDetailDashComponent/CasaDetailDashComponent";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useAPIContext } from "../../../contexts/APIContext";
import { useNavigate } from "react-router-dom";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { Add } from "@mui/icons-material";

function updateEmployeeInfo() {
  console.log("save employee info via api call");
}

const InformationWindow = (props) => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "5px" }}>
            <u>
              <b>Employee Details</b>
            </u>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="threeIntIDText"
            label="Three Int ID"
            disabled={!props.editMode}
            value={props.employee.EmplyID}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="firstNameText"
            label="First Name"
            disabled={!props.editMode}
            value={props.employeeData.EmplyFirst}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="lastNameText"
            label="Last Name"
            disabled={!props.editMode}
            value={props.employeeData.EmplyLast}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="midInitialText"
            label="Middle Initial"
            disabled={!props.editMode}
            value={props.employeeData.EmplyIntl}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="streetAddressText"
            label="Street Address"
            disabled={!props.editMode}
            value={props.employeeData.EmplyAddr}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressCityText"
            label="City"
            disabled={!props.editMode}
            value={props.employeeData.EmplyCity}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressStateText"
            label="State"
            disabled={!props.editMode}
            value={props.employeeData.EmplyStateX}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressZipText"
            label="Zip Code"
            disabled={!props.editMode}
            value={props.employeeData.EmplyZip}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="hireDateText"
            label="Hire Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={!props.editMode}
            value={props.employeeData.EmplyHire}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="statusText"
            label="Status"
            disabled={!props.editMode}
            value={props.employeeData.EmplyStatusX}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="notesText"
            label="Notes"
            multiline
            rows={4}
            disabled={!props.editMode}
            value={props.employeeData.EmplyNotes}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="departmentText"
            label="Department"
            disabled={!props.editMode}
            value={props.employeeData.EmplyDeptX}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="emergencyPhoneText"
            label="Emergency Phone"
            disabled={!props.editMode}
            value={props.employeeData.EmplyPh1}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="emergencyContactNameText"
            label="Emergency Contact Name"
            disabled={!props.editMode}
            value={props.employeeData.EmplyContact}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="homePhoneText"
            label="Home Phone"
            disabled={!props.editMode}
            value={props.employeeData.EmplyPh2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="mobilePhoneText"
            label="Mobile Phone"
            disabled={!props.editMode}
            value={props.employeeData.EmplyPh3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="workPhoneText"
            label="Work Phone"
            disabled={!props.editMode}
            value={props.employeeData.EmplyPh4}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="birthDateText"
            label="Birth Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={!props.editMode}
            value={props.employeeData.EmplyBday}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="ssnText"
            label="SSN"
            disabled={!props.editMode}
            value={props.employeeData.EmplySSN}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="dlnText"
            label="DLN"
            disabled={!props.editMode}
            value={props.employeeData.EmplyDLN}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PermissionsWindow = (props) => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "5px" }}>
            <u>
              <b>Time Clock</b>
            </u>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Clock Message"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Time Clock #"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Note"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Not Using the Time Clock (Do not include in time reports)"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Part Time (Do not track on 'not clocked in' report)"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Allow employee to 'Clock In' at any time"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Display 'Rain Out' message if it exists and prevent 'Clock In'"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Remote Time Clock"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              <u>
                <b>Payroll</b>
              </u>
            </Typography>
          </Grid>
          <FormControl variant="outlined" fullWidth disabled={!props.editMode}>
            <InputLabel>Payroll Type</InputLabel>
            <Select label="Payroll Type" value="GP Hours">
              <MenuItem value="GP Hours">GP Hours</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <u>
              <b>Additional Options</b>
            </u>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Unlock Invoices"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Customer Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Job Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Bin Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Equip Check Out"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Set Rain Out"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Mass Update"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Pull Sheets"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Change Dept"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Change Piping"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Edit PO Date"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Inv Sched Notes"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Inv Read Only"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Announcements"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Time Clock Admin"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Payroll"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Edit Employees"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ReportsWindow = (props) => {
  const [report, setReport] = React.useState("");
  const { administrationState } = useAdministrationContext(); //updateAdministration

  const reportsList = [
    "W2_2023.pdf",
    "W2_2024.pdf",
    "Payroll_Lifetime.pdf",
    "Summary.pdf",
  ];

  const handleChange = (event) => {
    setReport(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="findSearchSubtitle"
            sx={{
              fontWeight: "bold",
            }}
          >
            {reportsList.length} Reports Available
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, maxWidth: "60vw" }}
          >
            <InputLabel id="report-select-label">Report Type</InputLabel>
            <Select
              labelId="report-select-label"
              value={report}
              onChange={handleChange}
              label="Report Type"
            >
              {reportsList.map((reportName) => (
                <MenuItem key={reportName} value={reportName}>
                  {administrationState.employee.FullName.replace(/ /g, "_") +
                    "_" +
                    reportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={updateEmployeeInfo}
            disabled={!report}
          >
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const InventoryReports = (props) => {
  const [report, setReport] = React.useState("");
  const { administrationState } = useAdministrationContext(); //updateAdministration

  const reportsList = [
    "inv_changes_24h.pdf",
    "inv_changes_1w.pdf",
    "inv_changes_30d.pdf",
    "inv_changes_alltime.pdf",
  ];

  const handleChange = (event) => {
    setReport(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="findSearchSubtitle"
            sx={{
              fontWeight: "bold",
            }}
          >
            {reportsList.length} Reports Available
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, maxWidth: "60vw" }}
          >
            <InputLabel id="report-select-label">Report Type</InputLabel>
            <Select
              labelId="report-select-label"
              value={report}
              onChange={handleChange}
              label="Report Type"
            >
              {reportsList.map((reportName) => (
                <MenuItem key={reportName} value={reportName}>
                  {reportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={updateEmployeeInfo}
            disabled={!report}
          >
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const AppsWindow = (props) => {
  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <CasaSwitchForm />
    </Box>
  );
};

const CustomerWindow = (props) => {
  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Customer Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Customer" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="First Name" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="Type" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Address" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 2: System Details */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          System Details
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField label="System ID" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField label="Date" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label="DLP" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 3: Location */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Location
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="City" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="State" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Zip Code" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 4: Contact Details */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Contact Details
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Phone" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Fax" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Email" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 5: Billing Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Billing Information
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Accounting ID" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Billing Code" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Credit Card" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 6: Additional Options */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Additional Options
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="Place Customer on Hold"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="PO Required for Misc Job"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="Two Year Warranty"
        />
      </Grid>

      {/* Section 7: Estimators */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Estimators
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="AC Estimator" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="PL Estimator" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 8: Notes */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Notes
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Notes"
          multiline
          rows={4}
          fullWidth
          disabled={!props.editMode}
        />
      </Grid>

      {/* Section 9: Contacts */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Contacts
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Adam Wilson</TableCell>
              <TableCell>(210) 559-6096</TableCell>
              <TableCell>awilson@adamwilsonhomes.com</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ben Hudson</TableCell>
              <TableCell>(210) 426-9277</TableCell>
              <TableCell>ben@adamwilsonhomes.com</TableCell>
            </TableRow>
            {/* Add other rows as needed */}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

const EditInventoryItem = (props) => {
  const { inventoryState, updateInventory } = useInventoryContext();
  const { userState, updateUserState } = useUserStateContext();
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [editRow, setEditRow] = React.useState(props.editRow);

  useEffect(() => {
    const retrievedType = inventoryState.filterArrays[1]?.relatedRows.find(
      (item) =>
        item.ExtID === "2" + props.editRow.InvDept + "-" + props.editRow.InvType
    );
    const retrievedCategory = inventoryState.filterArrays[2]?.relatedRows.find(
      (item) =>
        item.ExtID ===
        "3" + props.editRow.InvType + "-" + props.editRow.InvCategory
    );
    const retrievedGroup = inventoryState.filterArrays[3]?.relatedRows.find(
      (item) => item.ExtID === "4-" + props.editRow.InvGroup
    );
    handleUpdateInventory("type", retrievedType.ExtID);
    handleUpdateInventory("category", retrievedCategory.ExtID);
    handleUpdateInventory("group", retrievedGroup.ExtID);
    setFiltersLoaded(true);
  }, []);

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        editType: value,
        editCategory: "",
        editGroup: "",
      });
      setEditRow((prev) => ({
        ...prev,
        InvType: value,
        InvCategory: "",
        InvGroup: "",
      }));
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      console.log("category results for (", value, "): ", results);
      if (results) {
        updateInventory({ editCategoryOptions: results });
      }
      updateInventory({ editGroupOptions: [] });
    } else if (type === "category") {
      updateInventory({
        editCategory: value,
        editGroup: "",
      });
      setEditRow((prev) => ({
        ...prev,
        InvCategory: "",
        InvGroup: "",
      }));
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      console.log("group results for (", value, "): ", results);
      if (results) {
        updateInventory({ editGroupOptions: results });
      } else {
        updateInventory({ editGroupOptions: [] });
      }
    } else if (type === "group") {
      setEditRow((prev) => ({
        ...prev,
        InvGroup: value,
      }));
      updateInventory({
        editGroup: value,
      });
    }
  }

  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="description-textfield"
          label="Description"
          defaultValue={editRow.InvDescription}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="mfg-num-textfield"
          label="Part #"
          defaultValue={editRow.InvPart}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Inventory Tags
        </Typography>
      </Grid>

      {/* <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="type-label">
            {!filtersLoaded ? "Loading..." : "Type"}
          </InputLabel>
          <Select
            fullWidth
            labelId="type-label"
            id="type"
            value={inventoryState.editType}
            onChange={(event) => {
              handleUpdateInventory("type", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Type"}
            disabled={!filtersLoaded}
          >
            {inventoryState.editTypeOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item xs={12} md={6}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="category-label">
            {!filtersLoaded ? "Loading..." : "Category"}
          </InputLabel>
          <Select
            fullWidth
            labelId="category-label"
            id="category"
            value={inventoryState.editCategory}
            onChange={(event) => {
              handleUpdateInventory("category", event.target.value);
            }}
            disabled={!props.editMode}
            label="Category"
          >
            {inventoryState.editCategoryOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="group-label">
            {!filtersLoaded ? "Loading..." : "Group"}
          </InputLabel>
          <Select
            fullWidth
            labelId="group-label"
            id="group"
            value={inventoryState.editGroup}
            onChange={(event) => {
              handleUpdateInventory("group", event.target.value);
            }}
            label="Group"
            disabled={!props.editMode}
          >
            {/* Group based on Category */}
            {/* {inventoryState.editGroupOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))} */}
            {inventoryState.groupArrsByDept[
              editRow.InvDept - 1
            ]?.relatedRows.map((option) => (
              <MenuItem key={option.ExtID} value={option.ExtID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Unit Details
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="cost-textfield"
          label="Cost"
          defaultValue={editRow.InvCost}
          InputProps={{
            readOnly: !props.editMode,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="unit-textfield"
          label="Unit"
          defaultValue={editRow.InvUnit}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="qty-textfield"
          label="Qty"
          defaultValue={editRow.InvQuantity}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Extras
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="notes-textfield"
          label="Notes"
          defaultValue={editRow.InvNotes}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
    </Grid>
  );
};

const CreateInventoryItem = (props) => {
  const { inventoryState, updateInventory } = useInventoryContext();
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const [incompleteCreateOpen, setIncompleteCreateOpen] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  async function handleAdd() {
    console.log("code ran");
    if (
      inventoryState.createType === "" ||
      inventoryState.createCategory === "" ||
      inventoryState.createGroup === "" ||
      inventoryState.createDescription === ""
    ) {
      setIncompleteCreateOpen(true);
    } else {
      const response = await createNewItem();
      if (Object.entries(response).length > 0) {
        navigate("/inventory/review");
      } else {
        alert("Failed to create item.");
      }
    }
  }

  async function createNewItem() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens(userState, updateUserState);

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "NewInvItem",
          Level1Parm: parseInt(userState.getDepartmentIndex() + 1),
          Level2Parm: inventoryState.createType.split("-")[1],
          InvCategory: inventoryState.createCategory.split("-")[1],
          InvGroup: inventoryState.createGroup.split("-")[1],
          InvDescription: inventoryState.createDescription,
          InvPart: inventoryState.createPartNum,
          InvCost: inventoryState.createCost,
          InvUnit: inventoryState.createUnit,
          InvQuantity: inventoryState.createQty,
          InvNotes: inventoryState.createNotes,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);

      if (response.data === null || response.data[0]?.StatusCode === "ERROR") {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        const id = response.data.InvID;
        let newItemResponse;
        updateInventory({
          createType: "",
          createCategory: "",
          createGroup: "",
          createDescription: "",
          createPartNum: "",
          createCost: "",
          createUnit: "",
          createQty: "",
          createNotes: "",
        });
        newItemResponse = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvItem",
            InvMode: 1,
            ItemID: id,
            Level1Parm: 0,
            Level2Parm: 0,
            Level3Parm: 0,
            Level4Parm: 0,
            Keywords: "",
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        console.log(newItemResponse);
        updateInventory({ createItem: newItemResponse.data[0] });
        return newItemResponse.data[0];
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        createType: value,
        createCategory: "",
        createGroup: "",
      });
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createCategoryOptions: results });
      }
      updateInventory({ createGroupOptions: [] });
    } else if (type === "category") {
      updateInventory({
        createCategory: value,
        createGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createGroupOptions: results });
      } else {
        updateInventory({ createGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        createGroup: value,
      });
    }
  }

  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="description-textfield"
          label="Description"
          defaultValue={inventoryState.createDescription}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={(e) => {
            updateInventory({ createDescription: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="mfg-num-textfield"
          label="Part #"
          defaultValue={inventoryState.createPartNum}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={(e) => {
            updateInventory({ createPartNum: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Inventory Tags
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            fullWidth
            labelId="type-label"
            id="type"
            value={inventoryState.createType}
            onChange={(event) => {
              handleUpdateInventory("type", event.target.value);
            }}
            label="Type"
          >
            {inventoryState.createTypeOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            fullWidth
            labelId="category-label"
            id="category"
            value={inventoryState.createCategory}
            onChange={(event) => {
              handleUpdateInventory("category", event.target.value);
            }}
            disabled={
              inventoryState.createCategoryOptions.length === 0 ||
              inventoryState.createType === ""
            }
            label="Category"
          >
            {inventoryState.createCategoryOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="group-label">Group</InputLabel>
          <Select
            fullWidth
            labelId="group-label"
            id="group"
            value={inventoryState.createGroup}
            onChange={(event) => {
              handleUpdateInventory("group", event.target.value);
            }}
            label="Group"
            disabled={
              inventoryState.createGroupOptions.length === 0 ||
              inventoryState.createCategory === ""
            }
          >
            {inventoryState.createGroupOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Unit Details
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="cost-textfield"
          label="Cost"
          defaultValue={inventoryState.createCost}
          InputProps={{
            readOnly: !props.editMode,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(e) => {
            updateInventory({ createCost: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="unit-textfield"
          label="Unit"
          defaultValue={inventoryState.createUnit}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={(e) => {
            updateInventory({ createUnit: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="qty-textfield"
          label="Qty"
          defaultValue={inventoryState.createQty}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={(e) => {
            updateInventory({ createQty: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Extras
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="notes-textfield"
          label="Notes"
          defaultValue={inventoryState.createNotes}
          InputProps={{
            readOnly: !props.editMode,
          }}
          onChange={(e) => {
            updateInventory({ createNotes: e.target.value });
          }}
        />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Create
        </Button>
      </Grid>
      <Dialog
        open={incompleteCreateOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Incomplete Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please add a description, type, category, and group to the item.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => {
              setIncompleteCreateOpen(false);
            }}
            sx={{ color: theme.palette.primary.main }}
          >
            Cancel
          </Button> */}
          <Button
            onClick={() => {
              setIncompleteCreateOpen(false);
            }}
            sx={{ color: theme.palette.primary.main }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const InventoryTags = (props) => {
  return props.content;
};

const InventoryLogs = (props) => {
  return props.content;
};

const TagDash = (props) => {
  return props.content;
};

const AdminTagEdit = (props) => {
  function handleCountChange(value, sortNum) {
    if (value === "") {
      value = 0;
    }
    if (sortNum === 1) {
      props.setNewRow((prevRow) => ({
        ...prevRow,
        sort1: value,
      }));
    } else if (sortNum === 2) {
      props.setNewRow((prevRow) => ({
        ...prevRow,
        sort2: value,
      }));
    } else {
      console.log("unknown sort num");
    }
  }

  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="description-textfield"
          label="Description"
          value={props.newRow.description}
          onChange={(e) => {
            props.setNewRow((prevRow) => ({
              ...prevRow,
              description: e.target.value,
            }));
          }}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Sort
        </Typography>
      </Grid>
      {(props.openTypes || props.openCategories) && (
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="mfg-num-textfield"
            label="Sort"
            type="number"
            onChange={(e) => {
              let value = e.target.value;

              // Allow "0" as a valid input on its own
              if (value === "0") {
                handleCountChange(value, 1);
              } else {
                // Remove leading zeros for all other cases
                value = value.replace(/^0+/, "");

                // Ensure the value is numeric
                if (/^\d*$/.test(value)) {
                  handleCountChange(value, 1);
                }
              }
            }}
            value={props.newRow.sort1}
            InputProps={{
              readOnly: !props.editMode,
            }}
          />
        </Grid>
      )}
      {props.openGroups && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="mfg-num-textfield"
              label="Sort 1"
              type="number"
              onChange={(e) => {
                let value = e.target.value;

                // Allow "0" as a valid input on its own
                if (value === "0") {
                  handleCountChange(value, 1);
                } else {
                  // Remove leading zeros for all other cases
                  value = value.replace(/^0+/, "");

                  // Ensure the value is numeric
                  if (/^\d*$/.test(value)) {
                    handleCountChange(value, 1);
                  }
                }
              }}
              value={props.newRow.sort1}
              InputProps={{
                readOnly: !props.editMode,
              }}
            />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="mfg-num-textfield"
              label="Sort 2"
              type="number"
              onChange={(e) => {
                let value = e.target.value;

                // Allow "0" as a valid input on its own
                if (value === "0") {
                  handleCountChange(value, 2);
                } else {
                  // Remove leading zeros for all other cases
                  value = value.replace(/^0+/, "");

                  // Ensure the value is numeric
                  if (/^\d*$/.test(value)) {
                    handleCountChange(value, 2);
                  }
                }
              }}
              value={props.newRow.sort2}
              InputProps={{
                readOnly: !props.editMode,
              }}
            />
          </Grid>
        </>
      )}
      {props.openCategories && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", paddingTop: "25px" }}
            >
              Parents
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="department-label">
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length > 0
                  ? "Department"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="department-label"
                id="department"
                value={props.newRow.department}
                // onChange={(event) => {
                //   handleUpdateInventory("category", event.target.value);
                // }}
                label="Department"
              >
                {props.inventoryState?.filterArrays[0]?.relatedRows?.map(
                  (option) => (
                    <MenuItem key={option.LevelID} value={option.LevelID}>
                      {option.LevelDesc}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="type-label">
                {props.inventoryState?.filterArrays[1]?.relatedRows?.length > 0
                  ? "Type"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="type-label"
                id="type"
                value={props.newRow.type}
                // onChange={(event) => {
                //   handleUpdateInventory("category", event.target.value);
                // }}
                label="Type"
              >
                {props.inventoryState?.filterArrays[1]?.relatedRows?.map(
                  (option) => (
                    <MenuItem key={option.LevelID} value={option.LevelID}>
                      {option.LevelDesc}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      {props.openGroups && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", paddingTop: "25px" }}
            >
              Parents
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="department-label">
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length > 0
                  ? "Department"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="department-label"
                id="department"
                value={props.newRow.department}
                // onChange={(event) => {
                //   handleUpdateInventory("category", event.target.value);
                // }}
                label="Department"
              >
                {props.inventoryState?.filterArrays[0]?.relatedRows?.map(
                  (option) => (
                    <MenuItem key={option.LevelID} value={option.LevelID}>
                      {option.LevelDesc}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", paddingTop: "25px" }}
              >
                Service
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ paddingTop: "15px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="service-flag-checkbox"
                    checked={props.newRow.serviceFlag}
                    onChange={(e) =>
                      props.setNewRow((prevRow) => ({
                        ...prevRow,
                        serviceFlag: e.target.checked ? 1 : 0,
                      }))
                    }
                    disabled={!props.editMode} // Disable checkbox if not in edit mode
                  />
                }
                label="Service Flag"
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const AdminTagCreate = (props) => {
  function handleCountChange(value, sortNum) {
    if (value === "") {
      value = 0;
    }
    if (sortNum === 1) {
      props.setNewRow((prevRow) => ({
        ...prevRow,
        sort1: value,
      }));
    } else if (sortNum === 2) {
      props.setNewRow((prevRow) => ({
        ...prevRow,
        sort2: value,
      }));
    } else {
      console.log("unknown sort num");
    }
  }

  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="description-textfield"
          label="Description"
          // error={!props.newRow.description}
          value={props.newRow.description}
          onChange={(e) => {
            props.setNewRow((prevRow) => ({
              ...prevRow,
              description: e.target.value,
            }));
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Sort
        </Typography>
      </Grid>
      {(props.openTypes || props.openCategories) && (
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="mfg-num-textfield"
            label="Sort"
            type="number"
            onChange={(e) => {
              let value = e.target.value;

              // Allow "0" as a valid input on its own
              if (value === "0") {
                handleCountChange(value, 1);
              } else {
                // Remove leading zeros for all other cases
                value = value.replace(/^0+/, "");

                // Ensure the value is numeric
                if (/^\d*$/.test(value)) {
                  handleCountChange(value, 1);
                }
              }
            }}
            value={props.newRow.sort1}
          />
        </Grid>
      )}
      {props.openGroups && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="mfg-num-textfield"
              label="Sort 1"
              type="number"
              onChange={(e) => {
                let value = e.target.value;

                // Allow "0" as a valid input on its own
                if (value === "0") {
                  handleCountChange(value, 1);
                } else {
                  // Remove leading zeros for all other cases
                  value = value.replace(/^0+/, "");

                  // Ensure the value is numeric
                  if (/^\d*$/.test(value)) {
                    handleCountChange(value, 1);
                  }
                }
              }}
              value={props.newRow.sort1}
            />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="mfg-num-textfield"
              label="Sort 2"
              type="number"
              onChange={(e) => {
                let value = e.target.value;

                // Allow "0" as a valid input on its own
                if (value === "0") {
                  handleCountChange(value, 2);
                } else {
                  // Remove leading zeros for all other cases
                  value = value.replace(/^0+/, "");

                  // Ensure the value is numeric
                  if (/^\d*$/.test(value)) {
                    handleCountChange(value, 2);
                  }
                }
              }}
              value={props.newRow.sort2}
            />
          </Grid>
        </>
      )}
      {props.openCategories && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", paddingTop: "25px" }}
            >
              Parents
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="department-label">
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length > 0
                  ? "Department"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="department-label"
                id="department"
                value={props.newRow.department}
                onChange={(e) => {
                  props.setNewRow((prevRow) => ({
                    ...prevRow,
                    department: e.target.value,
                    category: "",
                  }));
                }}
                label="Department"
                // error={!props.newRow.department}
                disabled={
                  props.inventoryState?.filterArrays[0]?.relatedRows?.length <=
                  0
                }
              >
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length >
                  0 &&
                  props.inventoryState?.filterArrays[0]?.relatedRows?.map(
                    (option) => (
                      <MenuItem key={option.LevelID} value={option.LevelID}>
                        {option.LevelDesc}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="type-label">
                {}
                {props.inventoryState?.filterArrays[1]?.relatedRows.length > 0
                  ? "Type"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="type-label"
                id="type"
                value={props.newRow.type}
                onChange={(e) => {
                  props.setNewRow((prevRow) => ({
                    ...prevRow,
                    type: e.target.value,
                  }));
                }}
                // error={!props.newRow.category}
                label="Type"
                disabled={
                  props.inventoryState?.filterArrays[1]?.relatedRows.length <= 0
                }
              >
                {props.inventoryState?.filters
                  ?.find(
                    (filter) =>
                      filter.parentId === "1-" + props.newRow.department
                  )
                  ?.relatedRows?.map((option) => (
                    <MenuItem key={option.LevelID} value={option.LevelID}>
                      {option.LevelDesc}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      {props.openGroups && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", paddingTop: "25px" }}
            >
              Parents
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="department-label">
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length > 0
                  ? "Department"
                  : "Loading..."}
              </InputLabel>
              <Select
                fullWidth
                labelId="department-label"
                id="department"
                value={props.newRow.department}
                // onChange={(event) => {
                //   handleUpdateInventory("category", event.target.value);
                // }}
                // error={!props.newRow.department}
                label="Department"
                disabled={
                  props.inventoryState?.filterArrays[0]?.relatedRows?.length <=
                  0
                }
              >
                {props.inventoryState?.filterArrays[0]?.relatedRows?.length >
                  0 &&
                  props.inventoryState?.filterArrays[0]?.relatedRows?.map(
                    (option) => (
                      <MenuItem key={option.LevelID} value={option.LevelID}>
                        {option.LevelDesc}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ textDecoration: "underline", paddingTop: "25px" }}
              >
                Service
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sx={{ paddingTop: "15px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="service-flag-checkbox"
                    checked={props.newRow.serviceFlag}
                    onChange={(e) =>
                      props.setNewRow((prevRow) => ({
                        ...prevRow,
                        serviceFlag: e.target.checked ? 1 : 0,
                      }))
                    }
                  />
                }
                label="Service Flag"
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <br />
        <center>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={props.createNewTag}
          >
            {props.loadingTags ? "Creating..." : "Create"}
          </Button>
        </center>
      </Grid>
    </Grid>
  );
};

const CustomView = (props) => {
  return <center>{props.content}</center>;
};

const CasaConfigureComponent = (props) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      <Paper
        sx={{
          width: "100%", // Full width of its container
          maxWidth: "1500px", // Maximum width if needed
          margin: "auto", // Center the Paper component
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh", // Adjust height as needed
          overflow: "hidden", // Hide overflow to keep content within Paper
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Close Icon */}
          <IconButton
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={props.closeModalFunction}
          >
            <CloseIcon />
          </IconButton>

          {/* Title */}
          <Typography
            variant="h4"
            sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
          >
            <b>{props.title}</b>
          </Typography>

          {/* Enable Edit/Save Icon */}
          {props.editEnabled && !props.createEnabled && (
            <IconButton
              sx={{
                color: "#fff",
                border: "1px solid #fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={() => {
                if (editMode) {
                  props.handleSave();
                }
                setEditMode(!editMode);
              }}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
          {props.createEnabled && !props.editEnabled && (
            <IconButton
              sx={{
                color: "#fff",
                border: "1px solid #fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={props.openCreate}
            >
              <Add />
            </IconButton>
          )}
          {!props.editEnabled && !props.createEnabled && (
            <IconButton
              sx={{
                color: "#ffffff00 !important",
                backgroundColor: "#ffffff00",
              }}
              disabled={true}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>

        {/* Scrollable Form Section */}
        <Box
          sx={{
            flex: 1, // Takes up the remaining space
            overflowY: "auto", // Enables vertical scrolling
            paddingTop: "5px",
            paddingBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {props.title === "Information" && (
            <InformationWindow
              editMode={editMode}
              employeeData={props.employeeData}
              employee={props.employee}
            />
          )}
          {props.title === "Permissions" && (
            <PermissionsWindow editMode={editMode} />
          )}
          {props.title === "Reports" && <ReportsWindow editMode={editMode} />}
          {props.title === "Apps" && <AppsWindow editMode={editMode} />}
          {props.title === "Customer" && <CustomerWindow editMode={editMode} />}
          {props.configType === "custom" && (
            <CustomView content={props.content} editRow={props.editRow} />
          )}
          {props.configType === "inv-details" && (
            <EditInventoryItem editMode={editMode} editRow={props.editRow} />
          )}
          {props.configType === "inv-admin-type" && (
            <InventoryTags content={props.content} />
          )}
          {props.configType === "inv-admin-category" && (
            <InventoryTags content={props.content} />
          )}
          {props.configType === "inv-admin-group" && (
            <InventoryTags content={props.content} />
          )}
          {props.configType === "inv-admin-tag-edit" && (
            <AdminTagEdit
              editMode={editMode}
              newRow={props.newRow}
              setNewRow={props.setNewRow}
              openTypes={props.openTypes}
              openCategories={props.openCategories}
              openGroups={props.openGroups}
              inventoryState={props.inventoryState}
            />
          )}
          {props.configType === "inv-admin-tag-create" && (
            <AdminTagCreate
              // editMode={editMode}
              newRow={props.newRow}
              setNewRow={props.setNewRow}
              openTypes={props.openTypes}
              openCategories={props.openCategories}
              openGroups={props.openGroups}
              inventoryState={props.inventoryState}
              userState={props.userState}
              createNewTag={props.createNewTag}
            />
          )}
          {props.title === "Inventory Logs" && (
            <InventoryLogs content={props.content} editMode={editMode} />
          )}
          {props.title === "Inventory Reports" && (
            <InventoryReports editMode={editMode} />
          )}
          {props.title === "Manage Tags" && <TagDash content={props.content} />}
          {props.title === "New Item" && (
            <CreateInventoryItem content={props.content} editMode={true} />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default CasaConfigureComponent;
